import React, { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/dist/client/router';
import { useLazyQuery, useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import { setCookie, removeCookie, getCookie } from 'utils/cookie';
import { apolloMobileLink } from 'graphql/apollo';
import { postCallbackMessage, PostMessageCallbackKey } from 'libs/bridge';
import getUserAgent from 'utils/userAgent';
import { QUERY_ME, SIGN_IN_DEVICE_DOCUMENT, SIGN_IN_SOCIAL_DOCUMENT } from 'graphql/auth';
import { COOKIE_NAME_TOKEN, COOKIE_NAME_USER_ID } from 'types/constants';
import { Union } from 'utils/types';
import { getUniqueId } from 'utils/guid';
import { ParsedUrlQuery } from 'querystring';
import { UrlObject } from 'url';
import useKakaoAuth, { UserProfile } from 'hooks/useKakaoAuth';
import { DeviceOsEnum, SignInDeviceMutation, SignInDeviceMutationVariables } from 'model/graphql';

export const AuthState = Object.freeze({
  unknown: 'unknown',
  notSignedIn: 'notSignedIn',
  signedIn: 'signedIn',
});
export type AuthStateType = Union<typeof AuthState>;

// TODO: userId도 User UserInfo로 이동해야 하지만 수정 범위가 너무 커져서 우선은 놔둠
interface AuthInfo {
  token: string | null;
  refreshToken: string | null;
  userId: number | null;
}

interface DeviceInfo {
  id: string | null;
  os: string | null;
  appVersion: string | null;
}

interface UserInfo {
  id: string | null;
  name: string | null;
  telephone: string | null;
  birth: string | null;
  gender: 1 | 2 | 3 | null;
  email: string | null;
  address: string | null;
  zipCode: string | null;
}

interface UserContextValue {
  auth?: AuthInfo;
  authState?: AuthStateType;
  device?: DeviceInfo;
  isSignedIn?: boolean;
  isNotSignedIn?: boolean;
  user?: UserInfo;
  signIn?: () => Promise<void>;
  signOut?: () => void;
}

const UserContext = React.createContext<UserContextValue>({});

const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  const [authState, setAuthState] = useState<AuthStateType>(AuthState.unknown);

  const { signInKakao, signOutKakao } = useKakaoAuth();

  const [auth, setAuth] = useState<AuthInfo>({
    token: null,
    refreshToken: null,
    userId: null,
  });

  const [device, setDevice] = useState<DeviceInfo>({
    id: null,
    os: null,
    appVersion: null,
  });

  const [user, setUser] = useState<UserInfo>({
    id: null,
    name: null,
    telephone: null,
    birth: null,
    gender: null,
    email: null,
    address: null,
    zipCode: null,
  });

  const [signInDevice] = useMutation<SignInDeviceMutation, SignInDeviceMutationVariables>(SIGN_IN_DEVICE_DOCUMENT, {
    client: apolloMobileLink,
  });
  const [signInSocialMutation] = useMutation(SIGN_IN_SOCIAL_DOCUMENT, { client: apolloMobileLink });
  const [getUser, { data: userData }] = useLazyQuery(QUERY_ME, {
    client: apolloMobileLink,
    fetchPolicy: 'no-cache',
  });

  // 아래는 unknown 상태를 고려하고 있지 않기 때문에 authState로 교체해야 하나 B/C를 위해 주석으로만 남겨 놓습니다.
  // const isSignedIn = useMemo<boolean>(() => authState === AuthState.signedIn, [authState]);
  const isSignedIn = useMemo<boolean>(() => !!auth.token && !!auth.userId, [auth.token, auth.userId]);
  const isNotSignedIn = useMemo<boolean>(() => authState === AuthState.notSignedIn, [authState]);

  useEffect(() => {
    if (router.pathname.includes('viberc')) {
      return;
    }

    if (window) {
      const initialAuthData = window.GD_WEBVIEW_AUTH;
      handleAuthData(initialAuthData);
    }

    const token = getCookie(COOKIE_NAME_TOKEN);
    const userId = getCookie(COOKIE_NAME_USER_ID);

    if (token) {
      setAuth({
        token,
        userId: userId ? Number(userId) : null,
        refreshToken: null,
      });
      setAuthState(AuthState.signedIn);
      getUser();
    } else {
      setAuthState(AuthState.notSignedIn);
    }

    const userAgent = getUserAgent();
    if (userAgent === 'IOS') {
      window?.addEventListener('message', onReceivedEvent);
      postCallbackMessage(PostMessageCallbackKey.authentication);
    } else if (userAgent === 'ANDROID') {
      document?.addEventListener('message', onReceivedEvent);
      postCallbackMessage(PostMessageCallbackKey.authentication);
    }
    return () => {
      if (window?.removeEventListener) window.removeEventListener('message', onReceivedEvent);
      if (document?.removeEventListener) document.removeEventListener('message', onReceivedEvent);
    };
  }, []);

  const emptyUser = () => {
    setUser({
      id: null,
      name: null,
      telephone: null,
      birth: null,
      gender: null,
      email: null,
      address: null,
      zipCode: null,
    });
  };

  useEffect(() => {
    if (userData?.me) {
      setUser({
        id: userData?.me?.id || null,
        name: userData?.me?.name || null,
        telephone: userData?.me?.telephone || null,
        email: userData?.me?.email || null,
        birth: userData?.me?.birth ? dayjs(userData?.me?.birth).year().toString() : 'NONE',
        gender: userData?.me?.gender === 'male' ? 1 : userData?.me.gender === 'female' ? 2 : 3,
        address: userData?.me?.address || null,
        zipCode: userData?.me?.zipCode || null,
      });
      setAuth((prev) => ({
        token: prev.token,
        userId: userData?.me?.id || null,
        refreshToken: null,
      }));
      setCookie(COOKIE_NAME_USER_ID, userData?.me?.id || null);
    } else {
      emptyUser();
    }
  }, [userData]);

  const onReceivedEvent = (e: any) => {
    try {
      const { type, data } = JSON.parse(e?.data);
      if (type !== 'onAuthenticationChange') return;
      handleAuthData(data);
    } catch (e) {
      console.error('Event Data Format Error', { e });
    }
  };

  const handleAuthData = (data: any) => {
    if (!data) return;

    const deviceId = data.deviceUniqueId || null;
    if (deviceId) {
      setDevice({
        id: deviceId,
        os: data.userProperties?.os || null,
        appVersion: data.userProperties?.appVersion || null,
      });
    }

    const token = data.accessToken ? `Bearer ${data.accessToken}` : null;
    const userId = token ? data.userId || data.currentUser?.id || null : null;

    setAuth({
      token,
      refreshToken: data.refreshToken || null,
      userId,
    });

    if (token) {
      setCookie(COOKIE_NAME_TOKEN, token);
      setCookie(COOKIE_NAME_USER_ID, userId);
      setAuthState(AuthState.signedIn);
      getUser();
    } else {
      signOut();
    }
  };

  const signOut = () => {
    emptyUser();
    removeCookie(COOKIE_NAME_TOKEN);
    removeCookie(COOKIE_NAME_USER_ID);
    setAuthState(AuthState.notSignedIn);
    setAuth({
      token: null,
      userId: null,
      refreshToken: null,
    });
    signOutKakao(() => {
      // do nothing
    });
  };

  const signIn = async () => {
    const { token: kakaoToken, profile } = await signInKakao();
    const uuid = getUniqueId();
    const destination = makeDestination(router.query);

    try {
      const { data } = await signInDevice({
        variables: {
          input: {
            os: DeviceOsEnum.Aos, // FIXME: web이기 때문에 OS ios/aos 중 하나를 선택할 수 없음 임시로 aos로 셋팅
            uuid: uuid ?? 'null',
            deviceId: 'none',
            appVersion: '0.0',
          },
        },
      });

      if (!data.signIn?.accessToken) {
        console.warn('accessToken of deviceSignIn is undefined');
        return;
      }

      const token = data.signIn.accessToken ? `Bearer ${data.signIn.accessToken}` : null;
      setAuth({
        token,
        userId: null,
        refreshToken: null,
      });
      setCookie(COOKIE_NAME_TOKEN, token);
      setAuthState(AuthState.signedIn);

      await signinGoodoc(kakaoToken, profile, destination);
    } catch (error) {
      console.warn(error.message);
    }
  };

  const signinGoodoc = async (kakaoToken: string, profile: UserProfile, destination?: UrlObject) => {
    const { uid } = profile;

    if (!uid || !kakaoToken) return;

    try {
      const { data } = await signInSocialMutation({
        variables: {
          input: {
            provider: 'kakao',
            uid,
            token: kakaoToken,
          },
        },
      });

      if (!data.signIn?.accessToken) {
        console.warn('accessToken of socialSignIn is undefined');
        return;
      }

      const token = data.signIn.accessToken ? `Bearer ${data.signIn.accessToken}` : null;
      setCookie(COOKIE_NAME_TOKEN, token);
      getUser();

      onSuccessSignIn(destination);
    } catch (error) {
      console.warn(error.message);
    }
  };

  const onSuccessSignIn = (destination?: UrlObject) => {
    if (destination) {
      router.replace(destination);
    } else if (window.history.state.idx > 0) {
      router.back();
    } else {
      router.replace({ pathname: '/' });
    }
  };

  const makeDestination = (query: ParsedUrlQuery) => {
    const { destination, ...restQuery } = query;

    if (!destination) return;

    return {
      pathname: String(destination),
      query: { ...restQuery },
    };
  };

  return (
    <UserContext.Provider
      value={{
        auth,
        authState,
        device,
        isSignedIn,
        isNotSignedIn,
        user,
        signIn,
        signOut,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
