import { ApolloClient, concat, createHttpLink, from, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { useMemo } from 'react';
import merge from 'deepmerge';
import { setContext } from '@apollo/client/link/context';
import { getCookie } from 'utils/cookie';
import { COOKIE_NAME_TOKEN } from 'types/constants';
import { isDevelopment } from 'utils/userAgent';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: ApolloClient<NormalizedCacheObject>;

export function addApolloState(client: any, pageProps: any) {
  if (pageProps?.props) {
    // eslint-disable-next-line no-param-reassign
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps: any) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}

const authLink = setContext((operation, { headers }) => {
  const token = getCookie(COOKIE_NAME_TOKEN);

  return {
    headers: {
      ...headers,
      Authorization: token,
      'x-api-token': token,
    },
  };
});

const mobileLink = concat(authLink, createHttpLink({ uri: process.env.GOODOC_API_MOBILE_ENDPOINT }));

export const apolloMobileLink = new ApolloClient({
  link: mobileLink,
  ssrMode: true,
  cache: new InMemoryCache(),
});

const clinicLink = concat(authLink, createHttpLink({ uri: process.env.GOODOC_API_CLINIC_ENDPOINT }));

export const apolloClinicLink = new ApolloClient({
  link: clinicLink,
  ssrMode: true,
  cache: new InMemoryCache(),
});

const clinicNewLink = concat(authLink, createHttpLink({ uri: process.env.GOODOC_API_CLINIC_NEW_ENDPOINT }));
export const apolloClinicNewLink = new ApolloClient({
  link: clinicNewLink,
  ssrMode: true,
  connectToDevTools: isDevelopment(),
  cache: new InMemoryCache(),
});

export function initializeApollo(initialState: any = null) {
  const currentApolloClient = apolloClinicNewLink;

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = currentApolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache);

    // Restore the cache with the merged data
    currentApolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return currentApolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = currentApolloClient;

  return currentApolloClient;
}
