// TODO : 추후 code gen으로 auto generate
import {
  EventsByIdsQuery,
  MedicalCurationsQuery,
  MedicalServicesByCategoryIdQuery,
  MedicalServicesByIdsQuery,
  NewMedicalServicesQuery,
  MedicalCategoryPropertiesByIdQuery,
  MedicalServicesRankingByCurationIdQuery,
  BookmarksQuery,
} from 'graphql/generated/graphql';
import { Union } from 'utils/types';

type ID = string;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };

export const curationType = Object.freeze({
  brand: 'brand',
  department: 'department',
  location: 'location',
  promotion: 'promotion',
  ranking: 'ranking',
  topic: 'topic',
});
export type CurationType = Union<typeof curationType>;

const propertyType = Object.freeze({ multi: 'multi' });
export type PropertyType = Union<typeof propertyType>;

export interface MedicalCategoryCurationMapping {
  medicalCategoryId: number;
  sortIndex: number;
  category: MedicalCategory;
}

export type MedicalCuration = MedicalCurationsQuery['list'][number];

export type CurationLocation = MedicalCurationsQuery['curationLocations'][number];

export interface MedicalCategory {
  id: string;
  name: string;
  depth?: number;
  enabled?: boolean;
  iconUrl?: string;
  children?: MedicalCategory[];
  medicalCategoryProperties?: MedicalCategoryProperty[];
  medicalServices?: MedicalService[];
  parentId?: number;
  parent?: MedicalCategory;
  secondDepthFilter?: boolean;
  sortIndex?: number;
  updatedAt?: string;
  chatBotEnabled?: boolean;
}

export interface MedicalCategoryPropertyValue {
  id: string;
  name: string;
  sortIndex: number;
}

export type MedicalCategoryProperty = MedicalCategoryPropertiesByIdQuery['medicalCategoryById']['medicalCategoryProperties'][number];

export interface Hospital {
  id: string;
  name: string;
  address: string;
  latitude?: number;
  longitude?: number;
  homepageUrl?: string;
  openedDate?: string;
}

export type Bookmark = BookmarksQuery['bookmarks']['list'][number];

export type ClinicEvent = EventsByIdsQuery['eventsByIds'][number] | Bookmark['clinicEvent'];

export type MedicalService = (
  | MedicalServicesByCategoryIdQuery['medicalServicesByCategoryId']['data'][number]
  | MedicalServicesByIdsQuery['medicalServicesByIds']['data'][number]
  | NewMedicalServicesQuery['newMedicalServices'][number]
  | MedicalServicesRankingByCurationIdQuery['medicalServicesRankingByCurationId'][number]
) & { medicalServiceSummary: { priceDetails?: Array<any> } };

export interface MedicalServiceEducation {
  school: {
    name: string;
    logoUrl: string;
  };
}

export interface MedicalServiceSummary {
  id: string;
  clinicEventName: string | null;
  hospitalName: string;
  bestReviews: MedicalServiceReview[];
  ratingMean: number;
  reviewsCount: number;
  address: string;
  latitude: number;
  longitude: number;
  mainDoctorEducation?: MedicalServiceEducation;
  openedDate: string;
  medicalCategoryNames: string[];
  priceDetails: MedicalServicePriceDetails[] | null;
}

const priceSource = ['hospital', 'crawler', 'hira', 'manual'] as const;
export type PriceSource = typeof priceSource[number];
export interface MedicalServicePriceDetails {
  price: number;
  medicalCategoryDepth: number;
  clinicEventId: string;
  medicalCategoryId: string;
  medicalCategoryName: string;
  priceSource: PriceSource;
}

export interface MedicalServiceReview {
  id: string;
  contents: string;
  rating: number;
}

export interface IdParam {
  id: string;
}

export interface PaginationParam {
  offset: number;
  limit: number;
}

export interface PopularMedicalServices {
  id: string;
  name: string;
  path: string;
  rank: number;
}

export interface EventCart {
  id: ID;
  clinicEventId: ID;
  clinicEvent: ClinicEvent;
  regDate?: string;
  lastCallRequestedAt?: string;
}

export const Gender = Object.freeze({
  male: 1,
  female: 2,
  unknown: 3,
});
export type GenderType = Union<typeof Gender>;

export interface CountEventCarts {
  countEventCarts: number;
}

export interface LacteaHospital {
  id: number;
  hospital_name: string;
  addr: string;
  short_addr: string;
}

export interface LacteaCallRequest {
  created_at: string;
  event?: {
    category_id?: number;
    category_name?: string;
    discounted_price?: number;
    end_on?: string;
    image?: {
      url?: string;
    };
    opened?: boolean;
    original_price?: number;
    period?: string;
    square_image?: string;
    title: string;
  };
  event_id?: number;
  hospital?: {
    name?: string;
    short_addr?: string;
  };
  hospital_id?: number;
  id?: number;
  recent_review_id?: number;
  review_status?: string;
}

export interface BestReview {
  id: ID;
  images: string[];
  contents: string;
  rating: number;
  createdAt: string;
  hospitalId: string;
  hospitalName: string;
  medicalCategoryName: string;
  medicalCategoryId: string;
  clinicEventName: string;
  lacteaReviewId: string;
  clinicEventId: string;
}

export interface LacteaClinicEvent {
  id: number;
  title: string;
  discounted_price: string;
  start_date: string;
  end_date: string;
  period: string;
  event_cost: number;
  original_price: string;
  numerical_discounted_price: number;
  numerical_original_price: number;
  event_type: number;
  hospital_id: number;
  image: {
    list: string;
    thumbnail: string;
  };
  one_depth: string;
  one_depth_id: string;
  two_depth: string;
  two_depth_id: string;
  wished: boolean;
}

export enum DeviceOsEnum {
  Aos = 'aos',
  Ios = 'ios',
}

export type SignInDeviceResult = {
  token: string;
};

export type SignInDeviceInput = {
  appVersion: string;
  deviceId: string;
  os: DeviceOsEnum;
  uuid: string;
};

export type SignInDeviceMutation = {
  signIn: { accessToken: string };
};

export type SignInDeviceMutationVariables = Exact<{
  input: SignInDeviceInput;
}>;
