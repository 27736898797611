import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/dist/client/router';

const FunnelContext = React.createContext<any>({});
const FunnelProvider = ({ children }: any) => {
  const router = useRouter();
  const [funnel, setFunnel] = useState('organic');

  useEffect(() => {
    setFunnel((router?.query?.funnel as string) || 'organic');
  }, [router?.query?.funnel]);

  return <FunnelContext.Provider value={{ funnel }}>{children}</FunnelContext.Provider>;
};
export { FunnelContext, FunnelProvider };
