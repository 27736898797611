import { Union } from 'utils/types';

/**
 * @deprecated 기존 코드를 위해 남겨둠, postCommandMessage 또는 postCallbackMessage를 사용하세요.
 */
export const postMessage = (message: string) => {
  window?.ReactNativeWebView?.postMessage(message);
};

const PostMessageType = Object.freeze({
  command: 'command',
  callback: 'CALLBACK',
});

export const PostMessageCommandKey = Object.freeze({
  close: 'close',
  back: 'close', // FIXME: 모바일에 back command가 아직 정의 되어 있지 않아 같은 역할을 하는 close로 사용. 모바일에 back 커맨드 정의가 되면 back으로 변경할 것 (close, back 모두 동일 동작임 - 네이밍 혼동으로 추가함)
  auth: 'auth',
  share: 'share',
  makeOnelinkAndShare: 'makeOnelinkAndShare',
  kakao: 'kakao',
  analyticsTrackEvent: 'analyticsTrackEvent',
});

export const PostMessageCallbackKey = Object.freeze({
  authentication: 'getAuthentication',
});

export const postCommandMessage = (messageKey: Union<typeof PostMessageCommandKey>, values?: Record<string, any>) => {
  const message =
    messageKey === PostMessageCommandKey.share
      ? { type: PostMessageType.command, key: messageKey, ...values }
      : { type: PostMessageType.command, key: messageKey, values };

  window?.ReactNativeWebView?.postMessage(JSON.stringify(message));
};

export const postCallbackMessage = (messageKey: Union<typeof PostMessageCallbackKey>, values?: Record<string, any>) => {
  const message = { type: PostMessageCallbackKey.authentication, key: messageKey, values };

  window?.ReactNativeWebView?.postMessage(JSON.stringify(message));
};
