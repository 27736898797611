import { ClientError } from 'graphql-request';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: any; output: any };
  JSONObject: { input: any; output: any };
};

export enum ActionTypeEnum {
  CallRequest = 'callRequest',
  OpenUrl = 'openUrl',
  Reservation = 'reservation',
}

export enum BabiStatusEnum {
  BeforeConfirmation = 'beforeConfirmation',
  ConfirmationComplete = 'confirmationComplete',
  RechargeComplete = 'rechargeComplete',
  RechargeNotPossible = 'rechargeNotPossible',
}

export enum BannerLinkTypeEnum {
  ClinicWeb = 'clinicWeb',
  Event = 'event',
  OutLink = 'outLink',
  Video = 'video',
  WebView = 'webView',
}

/** Mobile의 배너 Output */
export type BannerOutput = {
  __typename?: 'BannerOutput';
  createdAt: Scalars['DateTime']['output'];
  /** uber admin 등록유저 ID */
  createdBy: Scalars['ID']['output'];
  /** 종료 시간 */
  endAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** 이미지 S3 URL */
  imageUrl: Scalars['String']['output'];
  /** 노출 여부 */
  isVisible: Scalars['Boolean']['output'];
  /** 링크 타입 */
  linkType: BannerLinkTypeEnum;
  /** 링크 URL */
  linkUrl: Scalars['String']['output'];
  /** MedicalCuration */
  medicalCuration?: Maybe<MedicalCurationObject>;
  /** curationId */
  medicalCurationId?: Maybe<Scalars['ID']['output']>;
  /** 배너 위치 */
  position?: Maybe<BannerPositionEnum>;
  /** 정렬 순서 */
  sortIndex: Scalars['Int']['output'];
  /** 시작 시간 */
  startAt: Scalars['DateTime']['output'];
  /** 제목 */
  title: Scalars['String']['output'];
  /** 노출 영역 */
  type: BannerTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
  /** uber admin 수정유저 ID */
  updatedBy?: Maybe<Scalars['ID']['output']>;
};

export enum BannerPositionEnum {
  Main = 'main',
}

export enum BannerTypeEnum {
  BizCenter = 'bizCenter',
  Clinic = 'clinic',
  Curation = 'curation',
}

/** Mobile의 배너 목록 조건 */
export type BannersConditionInput = {
  /** 링크 타입 */
  linkType?: InputMaybe<BannerLinkTypeEnum>;
  /** curationId */
  medicalCurationId?: InputMaybe<Scalars['ID']['input']>;
  /** 배너 위치 */
  position?: InputMaybe<BannerPositionEnum>;
  /** 노출 영역 */
  type?: InputMaybe<BannerTypeEnum>;
};

/** Mobile의 배너 목록 Object */
export type BannersOutput = {
  __typename?: 'BannersOutput';
  /** Mobile의 배너 목록 */
  list: Array<BannerOutput>;
  /** Mobile의 배너 총 개수 */
  totalCount: Scalars['Int']['output'];
};

/** 이벤트 신청 Input */
export type CallRequestInput = {
  /** xxxx. 연도 4자리 */
  birth: Scalars['Int']['input'];
  /**  5(상시), 6(오전), 7(오후) */
  callBackTime: Scalars['Int']['input'];
  /** 이벤트 채널 */
  channel: ChannelTypeEnum;
  /** 클리닉 이벤트 아이디 */
  eventId: Scalars['ID']['input'];
  /** funnel */
  funnel: Scalars['String']['input'];
  /** 신청자 이름 */
  name: Scalars['String']['input'];
  /** 유저 핸드폰 번호 */
  phone: Scalars['String']['input'];
  /** 1. 남자, 2. 여자 */
  sex: Scalars['Int']['input'];
};

/** 바비톡 이벤트 신청 */
export type CallRequestObject = {
  __typename?: 'CallRequestObject';
  /** 이벤트 단가 실 소진액 */
  actualDbPrice?: Maybe<Scalars['Float']['output']>;
  /** 이벤트 단가 */
  babiCallRequestId?: Maybe<Scalars['ID']['output']>;
  /** 바비 클라이언트 아이디 */
  babiClientId: Scalars['ID']['output'];
  /** babi event 아이디 */
  babiEventId: Scalars['ID']['output'];
  /** 상태 */
  babiStatus?: Maybe<BabiStatusEnum>;
  /** 채널 상세 */
  channel: ChannelTypeEnum;
  /** clinic event 아이디 */
  clinicEventId: Scalars['ID']['output'];
  /** 생성일 */
  createdAt: Scalars['DateTime']['output'];
  /** 이벤트 단가 */
  dbPrice: Scalars['Float']['output'];
  /** 삭제일 */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** 중복 DB의 원인이 되는 Ask 레코드 */
  duplicatedBabiCallRequestId?: Maybe<Scalars['ID']['output']>;
  /** 퍼널 */
  funnel: Scalars['String']['output'];
  /** babi callRequest 아이디 */
  id: Scalars['ID']['output'];
  /** 중복 DB 여부 */
  isDuplicate?: Maybe<Scalars['Boolean']['output']>;
  /** 실패사유 */
  reason?: Maybe<Scalars['String']['output']>;
  /** Goodoc.Review ID */
  reviewId?: Maybe<Scalars['ID']['output']>;
  /** 부재문자 전송 수 */
  smsCount?: Maybe<Scalars['Float']['output']>;
  /** 콜 리퀘스트 상태 */
  status: StatusEnum;
  /** [트랜잭션 코드]20: 중복, 21: 결번 (자동 재충전), 22: 장기 부재 (재충전 신청 가능), 23: 본인 아님 (재충전 신청 가능) */
  transactionCode?: Maybe<TransactionCodeEnum>;
  /** 수정일 */
  updatedAt: Scalars['DateTime']['output'];
  /** 유저 아이디 */
  userId: Scalars['ID']['output'];
};

/** 이벤트 신청 데이터 Object */
export type CallRequestOutput = {
  __typename?: 'CallRequestOutput';
  /** 이벤트 단가 실 소진액 */
  actualDbPrice?: Maybe<Scalars['Float']['output']>;
  /** 이벤트 단가 */
  babiCallRequestId?: Maybe<Scalars['ID']['output']>;
  /** 바비 클라이언트 아이디 */
  babiClientId: Scalars['ID']['output'];
  /** babi event 아이디 */
  babiEventId: Scalars['ID']['output'];
  /** 상태 */
  babiStatus?: Maybe<BabiStatusEnum>;
  /** 채널 상세 */
  channel: ChannelTypeEnum;
  clinicEvent?: Maybe<ClinicEventWithHospital>;
  /** clinic event 아이디 */
  clinicEventId: Scalars['ID']['output'];
  /** 생성일 */
  createdAt: Scalars['DateTime']['output'];
  /** 이벤트 단가 */
  dbPrice: Scalars['Float']['output'];
  /** 삭제일 */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** 중복 DB의 원인이 되는 Ask 레코드 */
  duplicatedBabiCallRequestId?: Maybe<Scalars['ID']['output']>;
  /** 퍼널 */
  funnel: Scalars['String']['output'];
  /** babi callRequest 아이디 */
  id: Scalars['ID']['output'];
  /** 중복 DB 여부 */
  isDuplicate?: Maybe<Scalars['Boolean']['output']>;
  /** 실패사유 */
  reason?: Maybe<Scalars['String']['output']>;
  review?: Maybe<ReviewObject>;
  /** Goodoc.Review ID */
  reviewId?: Maybe<Scalars['ID']['output']>;
  /** 부재문자 전송 수 */
  smsCount?: Maybe<Scalars['Float']['output']>;
  /** 콜 리퀘스트 상태 */
  status: StatusEnum;
  /** [트랜잭션 코드]20: 중복, 21: 결번 (자동 재충전), 22: 장기 부재 (재충전 신청 가능), 23: 본인 아님 (재충전 신청 가능) */
  transactionCode?: Maybe<TransactionCodeEnum>;
  /** 수정일 */
  updatedAt: Scalars['DateTime']['output'];
  /** 유저 아이디 */
  userId: Scalars['ID']['output'];
};

/** 이벤트 신청 목록 Object */
export type CallRequestsOutput = {
  __typename?: 'CallRequestsOutput';
  list: Array<CallRequestOutput>;
  /** 데이터 총 개수 */
  totalCount: Scalars['Int']['output'];
};

export type CampaignCommentInput = {
  /** 캠페인 아이디 */
  campaignId: Scalars['ID']['input'];
  /** 댓글 */
  comment: Scalars['String']['input'];
  /** 유저 닉네임 */
  userNickName?: InputMaybe<Scalars['ID']['input']>;
};

/** 캠페인 댓글 */
export type CampaignCommentObject = {
  __typename?: 'CampaignCommentObject';
  /** 캠페인 아이디 */
  campaignId: Scalars['ID']['output'];
  /** 댓글 */
  comment: Scalars['String']['output'];
  /** 생성일 */
  createdAt: Scalars['DateTime']['output'];
  /** 캠페인 댓글 아이디 */
  id: Scalars['ID']['output'];
  /** 삭제 여부 */
  isDeleted: Scalars['Boolean']['output'];
  /** 수정일 */
  updatedAt: Scalars['DateTime']['output'];
  /** 유저 아이디 */
  userId: Scalars['ID']['output'];
  /** 유저 닉네임 */
  userNickName?: Maybe<Scalars['ID']['output']>;
};

export type CampaignCommentOutput = {
  __typename?: 'CampaignCommentOutput';
  data: Array<CampaignCommentObject>;
  /** 댓글 총 갯수 */
  totalCount: Scalars['Int']['output'];
};

export enum CategoryPropertySelectionTypeEnum {
  Multi = 'multi',
  Single = 'single',
}

export enum CategoryPropertyTypeEnum {
  Brand = 'brand',
  General = 'general',
  Inclusive = 'inclusive',
}

export enum CategoryTypeEnum {
  Beauty = 'beauty',
  Health = 'health',
}

export enum ChannelTypeEnum {
  ExternalAd = 'externalAd',
  GoodocApp = 'goodocApp',
  GoodocWeb = 'goodocWeb',
}

export type ClinicBookMarkOutput = {
  __typename?: 'ClinicBookMarkOutput';
  clinicEvent: ClinicEventWithHospitalOutput;
  /** 이벤트 아이디 */
  clinicEventId: Scalars['ID']['output'];
  /** 생성일 */
  createdAt: Scalars['DateTime']['output'];
  /** 병원 아이디 */
  hospitalId?: Maybe<Scalars['ID']['output']>;
  /** Lactea.ads 테이블의 id와 1:1 */
  id: Scalars['ID']['output'];
  /** 선택 여부 */
  selected: Scalars['Boolean']['output'];
  /** 수정일 */
  updatedAt: Scalars['DateTime']['output'];
  /** 유저 */
  userId: Scalars['ID']['output'];
};

export type ClinicBookMarksOutput = {
  __typename?: 'ClinicBookMarksOutput';
  /** 북마크 끝 여부 */
  isEnd: Scalars['Boolean']['output'];
  list: Array<ClinicBookMarkOutput>;
  /** 총 개수 */
  totalCount: Scalars['Int']['output'];
};

/** Bookmark Object */
export type ClinicBookmarkObject = {
  __typename?: 'ClinicBookmarkObject';
  /** 이벤트 아이디 */
  clinicEventId: Scalars['ID']['output'];
  /** 생성일 */
  createdAt: Scalars['DateTime']['output'];
  /** 병원 아이디 */
  hospitalId?: Maybe<Scalars['ID']['output']>;
  /** Lactea.ads 테이블의 id와 1:1 */
  id: Scalars['ID']['output'];
  /** 선택 여부 */
  selected: Scalars['Boolean']['output'];
  /** 수정일 */
  updatedAt: Scalars['DateTime']['output'];
  /** 유저 */
  userId: Scalars['ID']['output'];
};

/** 바비톡 클리닉 싱크 이벤트 */
export type ClinicEventObject = {
  __typename?: 'ClinicEventObject';
  /** 외부 광고 허용 여부 */
  allowExternalAd?: Maybe<Scalars['Boolean']['output']>;
  /** 굿닥 광고 허용 여부 */
  allowGoodocAd?: Maybe<Scalars['Boolean']['output']>;
  /** 바비 배너 ID */
  babiBannerId?: Maybe<Scalars['ID']['output']>;
  /** 바비 클라이언트 ID */
  babiClientId: Scalars['ID']['output'];
  /** 바비 이벤트 ID */
  babiEventId: Scalars['ID']['output'];
  /** 바비 병원 ID */
  babiHospitalId: Scalars['ID']['output'];
  /** 컨텐츠 이미지 */
  contentImage: Scalars['String']['output'];
  /** 생성일 */
  createdAt: Scalars['DateTime']['output'];
  /** DB 가격 (인입 건당 차감 금액) */
  dbPrice: Scalars['Int']['output'];
  /** 삭제일 */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** 종료 시간 */
  endAt: Scalars['DateTime']['output'];
  /** 병원 ID */
  hospitalId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  /** 기존 시작 시간 */
  originStartAt?: Maybe<Scalars['DateTime']['output']>;
  /** 원래 가격 */
  originalPrice: Scalars['Int']['output'];
  /** 굿닥 가격 */
  price: Scalars['Int']['output'];
  /** 부작용 문구 */
  sideEffect: Scalars['String']['output'];
  /** 시작 시간 */
  startAt: Scalars['DateTime']['output'];
  /** 진행 상태 */
  status: ClinicEventStatusEnum;
  /** 이벤트 서브 타이틀 */
  subTitle: Scalars['String']['output'];
  /** 이벤트 타겟 문구 */
  target: Scalars['String']['output'];
  /** 썸네일 이미지 */
  thumbnailImage?: Maybe<Scalars['String']['output']>;
  /** 이벤트명 */
  title: Scalars['String']['output'];
  /** 수정일 */
  updatedAt: Scalars['DateTime']['output'];
};

export enum ClinicEventStatusEnum {
  FundsExhausted = 'fundsExhausted',
  Live = 'live',
  Paused = 'paused',
  Pending = 'pending',
  UnderReview = 'underReview',
}

/** 클리닉 이벤트 데이터 Object */
export type ClinicEventWithHospital = {
  __typename?: 'ClinicEventWithHospital';
  /** 외부 광고 허용 여부 */
  allowExternalAd?: Maybe<Scalars['Boolean']['output']>;
  /** 굿닥 광고 허용 여부 */
  allowGoodocAd?: Maybe<Scalars['Boolean']['output']>;
  /** 바비 배너 ID */
  babiBannerId?: Maybe<Scalars['ID']['output']>;
  /** 바비 클라이언트 ID */
  babiClientId: Scalars['ID']['output'];
  /** 바비 이벤트 ID */
  babiEventId: Scalars['ID']['output'];
  /** 바비 병원 ID */
  babiHospitalId: Scalars['ID']['output'];
  /** 컨텐츠 이미지 */
  contentImage: Scalars['String']['output'];
  /** 생성일 */
  createdAt: Scalars['DateTime']['output'];
  /** DB 가격 (인입 건당 차감 금액) */
  dbPrice: Scalars['Int']['output'];
  /** 삭제일 */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** 종료 시간 */
  endAt: Scalars['DateTime']['output'];
  hospital?: Maybe<HospitalObject>;
  /** 병원 ID */
  hospitalId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  /** 기존 시작 시간 */
  originStartAt?: Maybe<Scalars['DateTime']['output']>;
  /** 원래 가격 */
  originalPrice: Scalars['Int']['output'];
  /** 굿닥 가격 */
  price: Scalars['Int']['output'];
  /** 부작용 문구 */
  sideEffect: Scalars['String']['output'];
  /** 시작 시간 */
  startAt: Scalars['DateTime']['output'];
  /** 진행 상태 */
  status: ClinicEventStatusEnum;
  /** 이벤트 서브 타이틀 */
  subTitle: Scalars['String']['output'];
  /** 이벤트 타겟 문구 */
  target: Scalars['String']['output'];
  /** 썸네일 이미지 */
  thumbnailImage?: Maybe<Scalars['String']['output']>;
  /** 이벤트명 */
  title: Scalars['String']['output'];
  /** 수정일 */
  updatedAt: Scalars['DateTime']['output'];
};

/** New ClinicEvent With Hospital */
export type ClinicEventWithHospitalOutput = {
  __typename?: 'ClinicEventWithHospitalOutput';
  /** 외부 광고 허용 여부 */
  allowExternalAd?: Maybe<Scalars['Boolean']['output']>;
  /** 굿닥 광고 허용 여부 */
  allowGoodocAd?: Maybe<Scalars['Boolean']['output']>;
  /** 바비 배너 ID */
  babiBannerId?: Maybe<Scalars['ID']['output']>;
  /** 바비 클라이언트 ID */
  babiClientId: Scalars['ID']['output'];
  /** 바비 이벤트 ID */
  babiEventId: Scalars['ID']['output'];
  /** 바비 병원 ID */
  babiHospitalId: Scalars['ID']['output'];
  /** 컨텐츠 이미지 */
  contentImage: Scalars['String']['output'];
  /** 생성일 */
  createdAt: Scalars['DateTime']['output'];
  /** DB 가격 (인입 건당 차감 금액) */
  dbPrice: Scalars['Int']['output'];
  /** 삭제일 */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** 종료 시간 */
  endAt: Scalars['DateTime']['output'];
  hospital?: Maybe<HospitalObject>;
  /** 병원 ID */
  hospitalId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  /** 기존 시작 시간 */
  originStartAt?: Maybe<Scalars['DateTime']['output']>;
  /** 원래 가격 */
  originalPrice: Scalars['Int']['output'];
  /** 굿닥 가격 */
  price: Scalars['Int']['output'];
  /** 부작용 문구 */
  sideEffect: Scalars['String']['output'];
  /** 시작 시간 */
  startAt: Scalars['DateTime']['output'];
  /** 진행 상태 */
  status: ClinicEventStatusEnum;
  /** 이벤트 서브 타이틀 */
  subTitle: Scalars['String']['output'];
  /** 이벤트 타겟 문구 */
  target: Scalars['String']['output'];
  /** 썸네일 이미지 */
  thumbnailImage?: Maybe<Scalars['String']['output']>;
  /** 이벤트명 */
  title: Scalars['String']['output'];
  /** 수정일 */
  updatedAt: Scalars['DateTime']['output'];
};

export enum ClinicHospitalOperatingStateEnum {
  Closed = 'closed',
  Operated = 'operated',
  Pended = 'pended',
  Unknown = 'unknown',
}

/** hospital With new ClinicEvent */
export type ClinicHospitalOutput = {
  __typename?: 'ClinicHospitalOutput';
  /** 주소 */
  address?: Maybe<Scalars['String']['output']>;
  clinicEvents?: Maybe<Array<ClinicEventObject>>;
  /** 생성일 */
  createdAt: Scalars['DateTime']['output'];
  /** 홈페이지URL */
  homepageUrl?: Maybe<Scalars['String']['output']>;
  hospitalOperationTimes?: Maybe<Scalars['String']['output']>;
  /** 병원 사진 목록 */
  hospitalPictures?: Maybe<Array<Scalars['String']['output']>>;
  /** 병원 ID */
  id: Scalars['ID']['output'];
  /** 위도 */
  latitude?: Maybe<Scalars['Float']['output']>;
  /** 경도 */
  longitude?: Maybe<Scalars['Float']['output']>;
  /** 병원이름 */
  name: Scalars['String']['output'];
  /** 개원일 */
  openedDate?: Maybe<Scalars['String']['output']>;
  /** 병원 운영 상태 */
  operatingState: ClinicHospitalOperatingStateEnum;
  /** 전화번호 */
  phone?: Maybe<Scalars['String']['output']>;
  reviewVisibility: Scalars['Boolean']['output'];
  safetyPhone?: Maybe<Scalars['String']['output']>;
  /** 수정일 */
  updatedAt: Scalars['DateTime']['output'];
  visibility: Scalars['Boolean']['output'];
};

/** 지역별 큐레이션 */
export type CurationLocationObject = {
  __typename?: 'CurationLocationObject';
  createdAt: Scalars['DateTime']['output'];
  /** 활성화 여부 */
  enabled: Scalars['Boolean']['output'];
  /** 아이콘 URL */
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** 병원 위치 위도 */
  latitude: Scalars['Float']['output'];
  /** 병원 위치 경도 */
  longitude: Scalars['Float']['output'];
  /** 지역명 */
  name: Scalars['String']['output'];
  /** 정렬 순서 */
  sortIndex: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum CurationTypeEnum {
  Brand = 'brand',
  Department = 'department',
  Location = 'location',
  Promotion = 'promotion',
  Ranking = 'ranking',
  Topic = 'topic',
}

export type EventCartObject = {
  __typename?: 'EventCartObject';
  /** 이벤트 아이디 */
  clinicEventId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** 병원 아이디 */
  hospitalId: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  /** 유저 아이디 */
  userId: Scalars['ID']['output'];
};

export type HospitalObject = {
  __typename?: 'HospitalObject';
  /** 주소 */
  address?: Maybe<Scalars['String']['output']>;
  /** 생성일 */
  createdAt: Scalars['DateTime']['output'];
  /** 홈페이지URL */
  homepageUrl?: Maybe<Scalars['String']['output']>;
  hospitalOperationTimes?: Maybe<Scalars['String']['output']>;
  /** 병원 사진 목록 */
  hospitalPictures?: Maybe<Array<Scalars['String']['output']>>;
  /** 병원 ID */
  id: Scalars['ID']['output'];
  /** 위도 */
  latitude?: Maybe<Scalars['Float']['output']>;
  /** 경도 */
  longitude?: Maybe<Scalars['Float']['output']>;
  /** 병원이름 */
  name: Scalars['String']['output'];
  /** 개원일 */
  openedDate?: Maybe<Scalars['String']['output']>;
  /** 병원 운영 상태 */
  operatingState: ClinicHospitalOperatingStateEnum;
  /** 전화번호 */
  phone?: Maybe<Scalars['String']['output']>;
  reviewVisibility: Scalars['Boolean']['output'];
  safetyPhone?: Maybe<Scalars['String']['output']>;
  /** 수정일 */
  updatedAt: Scalars['DateTime']['output'];
  visibility: Scalars['Boolean']['output'];
};

export type HospitalOperationObject = {
  __typename?: 'HospitalOperationObject';
  /** 생성일 */
  createdAt: Scalars['DateTime']['output'];
  /** hh:mm:ss */
  from: Scalars['String']['output'];
  holidayName?: Maybe<Scalars['String']['output']>;
  hospitalId: Scalars['ID']['output'];
  /** 병원 ID */
  id: Scalars['ID']['output'];
  operationOff: Scalars['Boolean']['output'];
  operationType: HospitalOperationTimeTypeEnum;
  targetDate?: Maybe<Scalars['DateTime']['output']>;
  /** hh:mm:ss */
  to: Scalars['String']['output'];
  /** 수정일 */
  updatedAt: Scalars['DateTime']['output'];
};

export enum HospitalOperationTimeTypeEnum {
  Friday = 'friday',
  Holiday = 'holiday',
  Monday = 'monday',
  Saturday = 'saturday',
  Sunday = 'sunday',
  Thursday = 'thursday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  WeekdayLunch = 'weekdayLunch',
  WeekendLunch = 'weekendLunch',
}

export type JsonStorageObject = {
  __typename?: 'JsonStorageObject';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  json: Array<Scalars['JSONObject']['output']>;
  key: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** 기존 굿닥 클리닉 이벤트 */
export type LegacyClinicEventObject = {
  __typename?: 'LegacyClinicEventObject';
  /** 기존 굿닥 클리닉 이벤트 ID */
  id: Scalars['ID']['output'];
  /** 기존 굿닥 클리닉 이벤트 이름 */
  name: Scalars['String']['output'];
};

/** 레거시 리뷰 */
export type LegacyReviewOutput = {
  __typename?: 'LegacyReviewOutput';
  /** 연계 예약 ID */
  appointmentId?: Maybe<Scalars['ID']['output']>;
  /** 후기글 */
  contents?: Maybe<Scalars['String']['output']>;
  /** 후기글 글자수 */
  contentsCount?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** 삭제일시 */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** 진료결과 */
  diagnosisScore?: Maybe<Scalars['Int']['output']>;
  /** 의사선생님 아이디 */
  doctorId?: Maybe<Scalars['ID']['output']>;
  /** 연계 이벤트 ID */
  eventId?: Maybe<Scalars['ID']['output']>;
  /** 사진유무 */
  existImages?: Maybe<Scalars['Boolean']['output']>;
  /** 시설/청결도 */
  facilityScore?: Maybe<Scalars['Int']['output']>;
  /** 노출 off 일시 */
  hiddenAt?: Maybe<Scalars['DateTime']['output']>;
  hospital?: Maybe<HospitalObject>;
  /** 연계 병원 ID */
  hospitalId?: Maybe<Scalars['ID']['output']>;
  /** ID */
  id: Scalars['ID']['output'];
  /** 사진 */
  images?: Maybe<Array<Scalars['String']['output']>>;
  /** 인증/비인증 */
  isVisited?: Maybe<Scalars['Boolean']['output']>;
  /** 친절도 */
  kindnessScore?: Maybe<Scalars['Int']['output']>;
  legacyClinicEvent?: Maybe<LegacyClinicEventObject>;
  /** 좋아요 개수 */
  likesCount?: Maybe<Scalars['Int']['output']>;
  /** 연계 약국 ID */
  pharmacyId?: Maybe<Scalars['ID']['output']>;
  /** 평점 */
  rating?: Maybe<Scalars['Int']['output']>;
  /** 연계 접수 ID */
  receiptId?: Maybe<Scalars['ID']['output']>;
  /** 재방문여부 */
  recommendation?: Maybe<Scalars['Boolean']['output']>;
  /** 리뷰 작성 경로 */
  source?: Maybe<ReviewSourceEnum>;
  /** 연계 리뷰 ID */
  targetId: Scalars['ID']['output'];
  /** 연계 클리닉시술 ID */
  treatmentId?: Maybe<Scalars['ID']['output']>;
  /** 진료항목 */
  treatmentName?: Maybe<Scalars['String']['output']>;
  /** 진료비용 */
  treatmentPrice?: Maybe<Scalars['Int']['output']>;
  /** 연계 비대면진료 ID */
  untactTreatmentId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** 작성한 유저아이디 */
  userId?: Maybe<Scalars['ID']['output']>;
  /** 리뷰 노출 */
  visibility: Scalars['Boolean']['output'];
  /** 방문일 */
  visitedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** 레거시 리뷰 목록 검색 조건 */
export type LegacyReviewsConditionInput = {
  /** 연계 병원 ID */
  hospitalId?: InputMaybe<Scalars['ID']['input']>;
};

/** 레거시 리뷰 목록 */
export type LegacyReviewsOutput = {
  __typename?: 'LegacyReviewsOutput';
  /** 레거시 리뷰 목록 */
  list: Array<LegacyReviewOutput>;
  /** 총 개수 */
  totalCount: Scalars['Int']['output'];
};

export type LocationSearchPresetObject = {
  __typename?: 'LocationSearchPresetObject';
  /** 생성일 */
  createdAt: Scalars['DateTime']['output'];
  /** ID */
  id: Scalars['ID']['output'];
  /** 위도 */
  latitude: Scalars['Float']['output'];
  /** 경도 */
  longitude: Scalars['Float']['output'];
  /** 지역명 */
  region: Scalars['String']['output'];
  /** 부가 설명 */
  subtitle?: Maybe<Scalars['String']['output']>;
  /** 위치명 */
  title: Scalars['String']['output'];
  /** 수정일 */
  updatedAt: Scalars['DateTime']['output'];
};

/** 건강매거진 */
export type MagazineObject = {
  __typename?: 'MagazineObject';
  /** 하단버튼 이름 */
  buttonName?: Maybe<Scalars['String']['output']>;
  /** 하단버튼 url */
  buttonUrl?: Maybe<Scalars['String']['output']>;
  /** 내용 */
  contents: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** uber admin 등록유저 ID */
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  /** 이미지 url */
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** 하단버튼 사용여부 */
  isButtonEnabled: Scalars['Boolean']['output'];
  /** 노출 여부 */
  isVisible: Scalars['Boolean']['output'];
  /** 페이지 url */
  pageUrl?: Maybe<Scalars['String']['output']>;
  /** 진짜 뷰 카운트 */
  realViewCount: Scalars['Int']['output'];
  /** 정렬 순서 */
  sortIndex?: Maybe<Scalars['Int']['output']>;
  /** 제목 */
  title: Scalars['String']['output'];
  /** 타입 */
  type?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** uber admin 수정유저 ID */
  updatedBy?: Maybe<Scalars['ID']['output']>;
  /** 뷰 카운트 */
  viewCount: Scalars['Int']['output'];
};

/** 건강 매거진 상세 Object */
export type MagazineOutput = {
  __typename?: 'MagazineOutput';
  /** 하단버튼 이름 */
  buttonName?: Maybe<Scalars['String']['output']>;
  /** 하단버튼 url */
  buttonUrl?: Maybe<Scalars['String']['output']>;
  /** 내용 */
  contents: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** 이미지 url */
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** 하단버튼 사용여부 */
  isButtonEnabled: Scalars['Boolean']['output'];
  /** 노출 여부 */
  isVisible: Scalars['Boolean']['output'];
  /** 페이지 url */
  pageUrl?: Maybe<Scalars['String']['output']>;
  /** 정렬 순서 */
  sortIndex?: Maybe<Scalars['Int']['output']>;
  /** 제목 */
  title: Scalars['String']['output'];
  /** 타입 */
  type?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** 뷰 카운트 */
  viewCount: Scalars['Int']['output'];
};

/** 건강 매거진 URL 목록 Object */
export type MagazineUrlOutput = {
  __typename?: 'MagazineUrlOutput';
  id: Scalars['ID']['output'];
  /** 페이지 url */
  pageUrl?: Maybe<Scalars['String']['output']>;
};

/** 건강 매거진 목록 Object */
export type MagazinesOutput = {
  __typename?: 'MagazinesOutput';
  /** 건강 매거진 데이터 목록 */
  list: Array<MagazineObject>;
  /** 건강 매거진 데이터 총 개수 */
  totalCount: Scalars['Int']['output'];
};

export type MedicalCategory2DepthTreeOutput = {
  __typename?: 'MedicalCategory2DepthTreeOutput';
  /** 카테고리 타입 */
  categoryType?: Maybe<CategoryTypeEnum>;
  /** chatbot 사용 여부 */
  chatBotEnabled?: Maybe<Scalars['Boolean']['output']>;
  children: Array<MedicalCategoryObject>;
  /** 생성일 */
  createdAt: Scalars['DateTime']['output'];
  /** 카테고리 뎁스 */
  depth: Scalars['Int']['output'];
  /** 카테고리 활성화 여부 */
  enabled: Scalars['Boolean']['output'];
  /** 카테고리 아이콘 URL */
  iconUrl?: Maybe<Scalars['String']['output']>;
  /** Lactea.ads 테이블의 id와 1:1 */
  id: Scalars['ID']['output'];
  /** 카테고리 키워드 */
  keywords: Array<Scalars['String']['output']>;
  /** 카테고리 이름 */
  name: Scalars['String']['output'];
  /** 부모 카테고리 ID */
  parentId?: Maybe<Scalars['Int']['output']>;
  /** 2depth 필터 사용 여부 */
  secondDepthFilter: Scalars['Boolean']['output'];
  /** 카테고리 정렬 순서 */
  sortIndex: Scalars['Int']['output'];
  /** 수정일 */
  updatedAt: Scalars['DateTime']['output'];
};

export type MedicalCategory3DepthTreeOutput = {
  __typename?: 'MedicalCategory3DepthTreeOutput';
  /** 카테고리 타입 */
  categoryType?: Maybe<CategoryTypeEnum>;
  /** chatbot 사용 여부 */
  chatBotEnabled?: Maybe<Scalars['Boolean']['output']>;
  children: Array<MedicalCategory2DepthTreeOutput>;
  /** 생성일 */
  createdAt: Scalars['DateTime']['output'];
  /** 카테고리 뎁스 */
  depth: Scalars['Int']['output'];
  /** 카테고리 활성화 여부 */
  enabled: Scalars['Boolean']['output'];
  /** 카테고리 아이콘 URL */
  iconUrl?: Maybe<Scalars['String']['output']>;
  /** Lactea.ads 테이블의 id와 1:1 */
  id: Scalars['ID']['output'];
  /** 카테고리 키워드 */
  keywords: Array<Scalars['String']['output']>;
  /** 카테고리 이름 */
  name: Scalars['String']['output'];
  /** 부모 카테고리 ID */
  parentId?: Maybe<Scalars['Int']['output']>;
  /** 2depth 필터 사용 여부 */
  secondDepthFilter: Scalars['Boolean']['output'];
  /** 카테고리 정렬 순서 */
  sortIndex: Scalars['Int']['output'];
  /** 수정일 */
  updatedAt: Scalars['DateTime']['output'];
};

export type MedicalCategoryCurationMappingObject = {
  __typename?: 'MedicalCategoryCurationMappingObject';
  /** 생성일 */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** 삭제 여부 */
  isDeleted: Scalars['Boolean']['output'];
  /** 카테고리 ID */
  medicalCategoryId: Scalars['Int']['output'];
  /** 큐레이션 ID */
  medicalCurationId: Scalars['Int']['output'];
  /** 정렬 순서 */
  sortIndex: Scalars['Int']['output'];
  /** 수정일 */
  updatedAt: Scalars['DateTime']['output'];
};

export type MedicalCategoryObject = {
  __typename?: 'MedicalCategoryObject';
  /** 카테고리 타입 */
  categoryType?: Maybe<CategoryTypeEnum>;
  /** chatbot 사용 여부 */
  chatBotEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** 생성일 */
  createdAt: Scalars['DateTime']['output'];
  /** 카테고리 뎁스 */
  depth: Scalars['Int']['output'];
  /** 카테고리 활성화 여부 */
  enabled: Scalars['Boolean']['output'];
  /** 카테고리 아이콘 URL */
  iconUrl?: Maybe<Scalars['String']['output']>;
  /** Lactea.ads 테이블의 id와 1:1 */
  id: Scalars['ID']['output'];
  /** 카테고리 키워드 */
  keywords: Array<Scalars['String']['output']>;
  /** 카테고리 이름 */
  name: Scalars['String']['output'];
  /** 부모 카테고리 ID */
  parentId?: Maybe<Scalars['Int']['output']>;
  /** 2depth 필터 사용 여부 */
  secondDepthFilter: Scalars['Boolean']['output'];
  /** 카테고리 정렬 순서 */
  sortIndex: Scalars['Int']['output'];
  /** 수정일 */
  updatedAt: Scalars['DateTime']['output'];
};

export type MedicalCategoryOutput = {
  __typename?: 'MedicalCategoryOutput';
  /** 카테고리 타입 */
  categoryType?: Maybe<CategoryTypeEnum>;
  /** chatbot 사용 여부 */
  chatBotEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** 생성일 */
  createdAt: Scalars['DateTime']['output'];
  /** 카테고리 뎁스 */
  depth: Scalars['Int']['output'];
  /** 카테고리 활성화 여부 */
  enabled: Scalars['Boolean']['output'];
  /** 카테고리 아이콘 URL */
  iconUrl?: Maybe<Scalars['String']['output']>;
  /** Lactea.ads 테이블의 id와 1:1 */
  id: Scalars['ID']['output'];
  /** 카테고리 키워드 */
  keywords: Array<Scalars['String']['output']>;
  medicalCategoryProperties: Array<MedicalCategoryPropertiesOutput>;
  /** 카테고리 이름 */
  name: Scalars['String']['output'];
  /** 부모 카테고리 ID */
  parentId?: Maybe<Scalars['Int']['output']>;
  /** 2depth 필터 사용 여부 */
  secondDepthFilter: Scalars['Boolean']['output'];
  /** 카테고리 정렬 순서 */
  sortIndex: Scalars['Int']['output'];
  /** 수정일 */
  updatedAt: Scalars['DateTime']['output'];
};

export type MedicalCategoryPropertiesOutput = {
  __typename?: 'MedicalCategoryPropertiesOutput';
  /** 생성일 */
  createdAt: Scalars['DateTime']['output'];
  /** 프로퍼티 활성화 여부 */
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  internalName?: Maybe<Scalars['String']['output']>;
  medicalCategoryId: Scalars['ID']['output'];
  medicalCategoryPropertyValues: Array<MedicalCategoryPropertyValueObject>;
  /** 프로퍼티 이름 */
  name: Scalars['String']['output'];
  propertyType: CategoryPropertyTypeEnum;
  selectionType: CategoryPropertySelectionTypeEnum;
  /** 프로퍼티 정렬 순서 */
  sortIndex: Scalars['Int']['output'];
  /** 수정일 */
  updatedAt: Scalars['DateTime']['output'];
  useDescription: Scalars['Boolean']['output'];
  useIcon: Scalars['Boolean']['output'];
};

export type MedicalCategoryPropertyValueMappingObject = {
  __typename?: 'MedicalCategoryPropertyValueMappingObject';
  createdAt: Scalars['DateTime']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  medicalCategoryPropertyId: Scalars['String']['output'];
  medicalCategoryPropertyValueId: Scalars['String']['output'];
  medicalServiceId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type MedicalCategoryPropertyValueObject = {
  __typename?: 'MedicalCategoryPropertyValueObject';
  /** 생성일 */
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** value 삭제 여부 */
  isDeleted: Scalars['Boolean']['output'];
  /** 카테고리 프로퍼티 id */
  medicalCategoryPropertyId: Scalars['String']['output'];
  /** 프로퍼티 value  */
  name: Scalars['String']['output'];
  origin?: Maybe<OriginTypeEnum>;
  /** 프로퍼티 value 정렬 순서 */
  sortIndex: Scalars['Int']['output'];
  /** 수정일 */
  updatedAt: Scalars['DateTime']['output'];
};

/** medical-curation 생성 data */
export type MedicalCurationJoinMappingAndCategoryOutput = {
  __typename?: 'MedicalCurationJoinMappingAndCategoryOutput';
  /** 생성일 */
  createdAt: Scalars['DateTime']['output'];
  /** 큐레이션 타입 */
  curationType: CurationTypeEnum;
  /** 사용 여부 */
  enabled: Scalars['Boolean']['output'];
  /** 아이콘 URL */
  iconUrl?: Maybe<Scalars['String']['output']>;
  /** ID */
  id: Scalars['ID']['output'];
  /** 카테고리 큐레이션 매핑 */
  medicalCategoryCurationMappings?: Maybe<Array<Maybe<MedicalCategoryCurationMappingObject>>>;
  /** 이름 */
  name: Scalars['String']['output'];
  /** 정렬 순서 */
  sortIndex: Scalars['Int']['output'];
  /** 수정일 */
  updatedAt: Scalars['DateTime']['output'];
};

export type MedicalCurationObject = {
  __typename?: 'MedicalCurationObject';
  /** 생성일 */
  createdAt: Scalars['DateTime']['output'];
  /** 큐레이션 타입 */
  curationType: CurationTypeEnum;
  /** 사용 여부 */
  enabled: Scalars['Boolean']['output'];
  /** 아이콘 URL */
  iconUrl?: Maybe<Scalars['String']['output']>;
  /** ID */
  id: Scalars['ID']['output'];
  /** 이름 */
  name: Scalars['String']['output'];
  /** 정렬 순서 */
  sortIndex: Scalars['Int']['output'];
  /** 수정일 */
  updatedAt: Scalars['DateTime']['output'];
};

/** medical-service 호출 */
export type MedicalServiceByCategoryIdInput = {
  /** 외부광고 허용 구분 */
  allowExternalAd?: InputMaybe<Scalars['Boolean']['input']>;
  /** 카테고리 프로퍼티 id 목록 */
  categoryPropertyValueIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** 카테고리 id */
  id: Scalars['ID']['input'];
  /** 위도 정보 */
  latitude: Scalars['Float']['input'];
  /** 경도 정보 */
  longitude: Scalars['Float']['input'];
  /** 최대거리 */
  maxDistance: Scalars['Float']['input'];
  /** 메디컬 서비스 아이디 목록 */
  medicalServiceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** 파트너 여부 */
  partnerOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** depth 구분 */
  serviceType?: Scalars['String']['input'];
  /** 정렬기준 */
  sortBy?: Scalars['String']['input'];
};

/** medicalServiceByCurationId */
export type MedicalServiceByCurationIdOutput = {
  __typename?: 'MedicalServiceByCurationIdOutput';
  /** 이벤트 신청, 예약, 그레이링크 */
  actionType: ActionTypeEnum;
  clinicEventId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  hospitalId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  marketPrice: Scalars['Boolean']['output'];
  medicalCategory: MedicalCategoryObject;
  medicalCategoryId: Scalars['ID']['output'];
  medicalServiceSummary: SimpleMedicalServiceSummaryOutput;
  /** 기존 시작 시간 */
  originStartAt?: Maybe<Scalars['DateTime']['output']>;
  originalPrice?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  priceSource?: Maybe<PriceSourceEnum>;
  priceType?: Maybe<PriceTypeEnum>;
  serviceType: ServiceTypeEnum;
  textPrice?: Maybe<Scalars['String']['output']>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

/** 병원 아이디 기반으로 medical-service 호출 */
export type MedicalServiceByHospitalIdInput = {
  /** medicalService actionType */
  actionTypes: Array<ActionTypeEnum>;
  /** hospitalId */
  hospitalId: Scalars['ID']['input'];
};

export type MedicalServiceByIdOutput = {
  __typename?: 'MedicalServiceByIdOutput';
  /** 이벤트 신청, 예약, 그레이링크 */
  actionType: ActionTypeEnum;
  clinicEventId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  hospital: HospitalObject;
  hospitalId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  marketPrice: Scalars['Boolean']['output'];
  medicalCategory: MedicalCategoryOutput;
  medicalCategoryId: Scalars['ID']['output'];
  medicalCategoryPropertyValueMappings: Array<MedicalCategoryPropertyValueMappingObject>;
  medicalServiceSummary: MedicalServiceSummaryObject;
  /** 기존 시작 시간 */
  originStartAt?: Maybe<Scalars['DateTime']['output']>;
  originalPrice?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  priceSource?: Maybe<PriceSourceEnum>;
  priceType?: Maybe<PriceTypeEnum>;
  serviceType: ServiceTypeEnum;
  textPrice?: Maybe<Scalars['String']['output']>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type MedicalServiceByIdsAndCountOutput = {
  __typename?: 'MedicalServiceByIdsAndCountOutput';
  data: Array<MedicalServiceByIdsWithOthersOutput>;
  totalCount: Scalars['Float']['output'];
};

export type MedicalServiceByIdsOutput = {
  __typename?: 'MedicalServiceByIdsOutput';
  data: Array<MedicalServiceByIdsWithOthersOutput>;
};

export type MedicalServiceByIdsWithOthersOutput = {
  __typename?: 'MedicalServiceByIdsWithOthersOutput';
  /** 이벤트 신청, 예약, 그레이링크 */
  actionType: ActionTypeEnum;
  clinicEventId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  hospitalId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  marketPrice: Scalars['Boolean']['output'];
  medicalCategory: MedicalCategoryObject;
  medicalCategoryId: Scalars['ID']['output'];
  medicalServiceSummary: MedicalServiceSummaryObject;
  /** 기존 시작 시간 */
  originStartAt?: Maybe<Scalars['DateTime']['output']>;
  originalPrice?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  priceSource?: Maybe<PriceSourceEnum>;
  priceType?: Maybe<PriceTypeEnum>;
  serviceType: ServiceTypeEnum;
  textPrice?: Maybe<Scalars['String']['output']>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type MedicalServiceOutput = {
  __typename?: 'MedicalServiceOutput';
  data: Array<MedicalServiceOutputWithOthers>;
  totalCount: Scalars['Float']['output'];
};

export type MedicalServiceOutputWithOthers = {
  __typename?: 'MedicalServiceOutputWithOthers';
  /** 이벤트 신청, 예약, 그레이링크 */
  actionType: ActionTypeEnum;
  clinicEventId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  hospitalId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  marketPrice: Scalars['Boolean']['output'];
  medicalCategory: MedicalCategoryObject;
  medicalCategoryId: Scalars['ID']['output'];
  medicalCategoryPropertyValueMappings: Array<MedicalCategoryPropertyValueMappingObject>;
  medicalServiceSummary: MedicalServiceSummaryObject;
  /** 기존 시작 시간 */
  originStartAt?: Maybe<Scalars['DateTime']['output']>;
  originalPrice?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  priceSource?: Maybe<PriceSourceEnum>;
  priceType?: Maybe<PriceTypeEnum>;
  serviceType: ServiceTypeEnum;
  textPrice?: Maybe<Scalars['String']['output']>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type MedicalServiceSummaryObject = {
  __typename?: 'MedicalServiceSummaryObject';
  address?: Maybe<Scalars['String']['output']>;
  clinicEventName?: Maybe<Scalars['String']['output']>;
  clinicScore: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** 병원의 의사 목록 */
  doctors: Array<Scalars['JSONObject']['output']>;
  endAt?: Maybe<Scalars['DateTime']['output']>;
  hospitalName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  mainDoctorEducation?: Maybe<Scalars['JSONObject']['output']>;
  medicalCategoryNames: Array<Scalars['String']['output']>;
  medicalServiceId: Scalars['String']['output'];
  openedDate?: Maybe<Scalars['String']['output']>;
  priceDetails: Array<Scalars['JSONObject']['output']>;
  ratingMean: Scalars['Float']['output'];
  reviewsCount: Scalars['Int']['output'];
  startAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type MedicalServiceWithSummaryOutput = {
  __typename?: 'MedicalServiceWithSummaryOutput';
  /** 이벤트 신청, 예약, 그레이링크 */
  actionType: ActionTypeEnum;
  clinicEventId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  hospitalId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  marketPrice: Scalars['Boolean']['output'];
  medicalCategoryId: Scalars['ID']['output'];
  medicalServiceSummary: MedicalServiceSummaryObject;
  /** 기존 시작 시간 */
  originStartAt?: Maybe<Scalars['DateTime']['output']>;
  originalPrice?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  priceSource?: Maybe<PriceSourceEnum>;
  priceType?: Maybe<PriceTypeEnum>;
  serviceType: ServiceTypeEnum;
  textPrice?: Maybe<Scalars['String']['output']>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createCallRequest: CallRequestObject;
  createCampaignComment: CampaignCommentObject;
  /** eventCart ADD API */
  createEventCart: EventCartObject;
  createJsonStorage: JsonStorageObject;
  createReservation: ReservationHistoryObject;
  deleteCampaignComment: Scalars['Boolean']['output'];
  /** eventCart DELETE API */
  deleteEventCarts: Scalars['Boolean']['output'];
  increaseMagazineViewCount: Scalars['Int']['output'];
  /** 북마크 체크 API */
  toggleBookmark: ClinicBookmarkObject;
  /** 10분간격, 바비톡 추가된 이벤트 업데이트 로직 */
  upsertClinicEventsEveryTenMinutes: Scalars['Boolean']['output'];
  /** 1시간 간격 , 바비톡 삭제된 이벤트 업데이트 로직 */
  upsertClinicEventsHourly: Scalars['Boolean']['output'];
};

export type MutationCreateCallRequestArgs = {
  data: CallRequestInput;
};

export type MutationCreateCampaignCommentArgs = {
  data: CampaignCommentInput;
};

export type MutationCreateEventCartArgs = {
  eventId: Scalars['ID']['input'];
};

export type MutationCreateJsonStorageArgs = {
  data: Array<Scalars['JSONObject']['input']>;
  key: Scalars['ID']['input'];
};

export type MutationCreateReservationArgs = {
  data: ReservationHistoryInput;
};

export type MutationDeleteCampaignCommentArgs = {
  commentId: Scalars['String']['input'];
};

export type MutationDeleteEventCartsArgs = {
  eventIds: Array<Scalars['String']['input']>;
};

export type MutationIncreaseMagazineViewCountArgs = {
  id: Scalars['ID']['input'];
};

export type MutationToggleBookmarkArgs = {
  eventId: Scalars['ID']['input'];
  value: Scalars['Boolean']['input'];
};

/** 나의 이벤트 신청 목록 Input */
export type MyCallRequestsInput = {
  /** 신청 날짜 검색 범위 시작 값 */
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** 신청 날짜 검색 범위 끝 값 */
  createdAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  /** 리뷰 존재 여부 */
  isReviewed?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum OriginTypeEnum {
  Domestic = 'domestic',
  Imported = 'imported',
}

/** 페이지 옵션 */
export type Pagination = {
  /** 페이징 Skip */
  skip?: Scalars['Int']['input'];
  /** 페이징 Take */
  take?: Scalars['Int']['input'];
};

export enum PopularHospitalByEnum {
  Category = 'category',
}

export type PopularHospitalObject = {
  __typename?: 'PopularHospitalObject';
  /** 구분 조건 */
  categorizeBy: PopularHospitalByEnum;
  /** 생성일 */
  createdAt: Scalars['DateTime']['output'];
  /** 병원 리스트 */
  hospitals?: Maybe<Array<Scalars['JSONObject']['output']>>;
  /** ID */
  id: Scalars['ID']['output'];
  /** 인기기준 이름 */
  name: Scalars['String']['output'];
  /** 정렬 */
  sortIndex: Scalars['Int']['output'];
  /** 수정일 */
  updatedAt: Scalars['DateTime']['output'];
};

export type PopularMedicalServiceObject = {
  __typename?: 'PopularMedicalServiceObject';
  /** 생성일 */
  createdAt: Scalars['DateTime']['output'];
  /** ID */
  id: Scalars['ID']['output'];
  /** 인기시술 이미지 URL */
  imageUrl: Scalars['String']['output'];
  /** 인기시술 이름 */
  name: Scalars['String']['output'];
  /** 인기시술 path */
  path: Scalars['String']['output'];
  /** 순위 */
  rank: Scalars['Int']['output'];
  /** 수정일 */
  updatedAt: Scalars['DateTime']['output'];
};

export enum PriceSourceEnum {
  Crawler = 'crawler',
  Hira = 'hira',
  Hospital = 'hospital',
  Manual = 'manual',
}

export enum PriceTypeEnum {
  Fixed = 'fixed',
  Max = 'max',
  Min = 'min',
}

export type Query = {
  __typename?: 'Query';
  /** Mobile의 배너 목록 */
  banners: BannersOutput;
  /** 리뷰 목록 by MedicalServiceId */
  bestReviewsByMedicalServiceId: ReviewsOutput;
  /** 북마크 리스트 API */
  bookmarks: ClinicBookMarksOutput;
  /** 500 에러 호출 */
  call500Exception: Scalars['Boolean']['output'];
  /** Clinic 데이터베이스 호출 - 건강 매거진 개수 */
  callClinicDatabase: Scalars['Int']['output'];
  /** 500 에러가 아닌 다른 예외 호출 */
  callOtherException: Scalars['Boolean']['output'];
  /** Redis 사용 테스트 */
  callRedis: Scalars['String']['output'];
  campaignComments: CampaignCommentOutput;
  clinicEvent: ClinicEventWithHospitalOutput;
  clinicEvents: Array<ClinicEventWithHospitalOutput>;
  /** eventCart Count API */
  countEventCarts: Scalars['Int']['output'];
  curationLocations: Array<CurationLocationObject>;
  eventsByHospitalId: ClinicHospitalOutput;
  /** Get Clinic Events API by IDs */
  eventsByIds: Array<ClinicEventWithHospitalOutput>;
  /** 해당 병원의 운영 시간 목록 API */
  hospitalOperationTimesByHospitalId: Array<HospitalOperationObject>;
  /** 북마크 여부 확인 API */
  isBookmarked: Scalars['Boolean']['output'];
  jsonStorageByKey: JsonStorageObject;
  /** 레거시 리뷰 목록 */
  legacyReviews: LegacyReviewsOutput;
  locationSearchPresets: Array<LocationSearchPresetObject>;
  magazine: MagazineOutput;
  /** 페이지 URL 전체 목록 */
  magazineUrls: Array<MagazineUrlOutput>;
  magazines: MagazinesOutput;
  /** 홈 화면 베스트 리뷰 리스트 */
  mainBestReviews: Array<ReviewAtMainObject>;
  medicalCategories: Array<MedicalCategoryObject>;
  medicalCategoryById: MedicalCategoryOutput;
  medicalCategoryTree: Array<MedicalCategory3DepthTreeOutput>;
  medicalCurations: Array<MedicalCurationJoinMappingAndCategoryOutput>;
  medicalServiceById: MedicalServiceByIdOutput;
  medicalServicesByCategoryId: MedicalServiceOutput;
  medicalServicesByHospitalId: Array<MedicalServiceWithSummaryOutput>;
  medicalServicesByIds: MedicalServiceByIdsOutput;
  medicalServicesRankingByCurationId: Array<MedicalServiceByCurationIdOutput>;
  myCallRequests: CallRequestsOutput;
  myReservations: Array<ReservationHistoryObject>;
  newMedicalServices: Array<MedicalServiceByIdsWithOthersOutput>;
  newMedicalServicesWithPagination: MedicalServiceByIdsAndCountOutput;
  popularHospitals: Array<PopularHospitalObject>;
  popularMedicalServices: Array<PopularMedicalServiceObject>;
  /** 다른고객이 함께 본 상품 API */
  recommendedEvents: Array<ClinicEventWithHospitalOutput>;
  similarEvents: Array<ClinicEventWithHospitalOutput>;
};

export type QueryBannersArgs = {
  condition: BannersConditionInput;
};

export type QueryBestReviewsByMedicalServiceIdArgs = {
  medicalServiceId: Scalars['ID']['input'];
  pagination: Pagination;
};

export type QueryBookmarksArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type QueryCampaignCommentsArgs = {
  campaignId: Scalars['String']['input'];
  pagination: Pagination;
};

export type QueryClinicEventArgs = {
  eventId: Scalars['String']['input'];
};

export type QueryClinicEventsArgs = {
  eventIds: Array<Scalars['String']['input']>;
};

export type QueryEventsByHospitalIdArgs = {
  hospitalId: Scalars['String']['input'];
};

export type QueryEventsByIdsArgs = {
  eventIds?: Array<Scalars['ID']['input']>;
};

export type QueryHospitalOperationTimesByHospitalIdArgs = {
  hospitalId: Scalars['ID']['input'];
};

export type QueryIsBookmarkedArgs = {
  eventId: Scalars['ID']['input'];
};

export type QueryJsonStorageByKeyArgs = {
  key: Scalars['ID']['input'];
};

export type QueryLegacyReviewsArgs = {
  condition: LegacyReviewsConditionInput;
  pagination: Pagination;
};

export type QueryMagazineArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  pageUrl?: InputMaybe<Scalars['String']['input']>;
};

export type QueryMagazinesArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  pagination: Pagination;
};

export type QueryMedicalCategoryByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryMedicalServiceByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryMedicalServicesByCategoryIdArgs = {
  input: MedicalServiceByCategoryIdInput;
  pagination: Pagination;
};

export type QueryMedicalServicesByHospitalIdArgs = {
  input: MedicalServiceByHospitalIdInput;
};

export type QueryMedicalServicesByIdsArgs = {
  input: Array<Scalars['String']['input']>;
};

export type QueryMedicalServicesRankingByCurationIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryMyCallRequestsArgs = {
  condition?: InputMaybe<MyCallRequestsInput>;
  pagination: Pagination;
};

export type QueryNewMedicalServicesWithPaginationArgs = {
  pagination: Pagination;
};

export type QueryPopularHospitalsArgs = {
  categorizeBy: Scalars['String']['input'];
};

export type QueryRecommendedEventsArgs = {
  eventId: Scalars['ID']['input'];
};

export type QuerySimilarEventsArgs = {
  eventId: Scalars['String']['input'];
};

/** 예약 입력 조건 */
export type ReservationHistoryInput = {
  birth?: InputMaybe<Scalars['String']['input']>;
  hospitalId: Scalars['String']['input'];
  medicalServiceId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  reservationAt: Scalars['DateTime']['input'];
  sex?: InputMaybe<SexEnum>;
};

/** 예약 */
export type ReservationHistoryObject = {
  __typename?: 'ReservationHistoryObject';
  birth?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  hospitalId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  medicalServiceId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  reservationAt: Scalars['DateTime']['output'];
  sex?: Maybe<SexEnum>;
  status: ReservationStatusEnum;
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
};

export enum ReservationStatusEnum {
  NoContact = 'noContact',
  Requested = 'requested',
  Reserved = 'reserved',
  Visited = 'visited',
}

export type ReviewAtMainObject = {
  __typename?: 'ReviewAtMainObject';
  /** 이벤트 Id */
  clinicEventId: Scalars['String']['output'];
  /** 이벤트 이름 */
  clinicEventName: Scalars['String']['output'];
  /** 후기글 */
  contents?: Maybe<Scalars['String']['output']>;
  /** 후기글 글자수 */
  contentsCount?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** 연계 병원 ID */
  hospitalId?: Maybe<Scalars['ID']['output']>;
  /** 클라이언트에 노출되는 병원명 */
  hospitalName: Scalars['String']['output'];
  /** ID */
  id: Scalars['ID']['output'];
  /** 사진 */
  images?: Maybe<Array<Scalars['String']['output']>>;
  /** Lactea Review ID */
  lacteaReviewId: Scalars['String']['output'];
  /** 메디컬 카테고리 Id */
  medicalCategoryId: Scalars['String']['output'];
  /** 메디컬 카테고리 이름 */
  medicalCategoryName: Scalars['String']['output'];
  /** 평점 */
  rating?: Maybe<Scalars['Int']['output']>;
  /** 병원, 약국, 클리닉시술, 이벤트, 비대면 리뷰로 구분 */
  reviewType: ReviewTypeEnum;
  /** 연계 리뷰 ID */
  targetId: Scalars['ID']['output'];
  /** 리뷰 노출 */
  visibility: Scalars['Boolean']['output'];
  /** 방문일 */
  visitedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Goodoc.Review */
export type ReviewObject = {
  __typename?: 'ReviewObject';
  /** 연계 예약 ID */
  appointmentId?: Maybe<Scalars['ID']['output']>;
  /** 후기글 */
  contents?: Maybe<Scalars['String']['output']>;
  /** 후기글 글자수 */
  contentsCount?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** 삭제일시 */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** 진료결과 */
  diagnosisScore?: Maybe<Scalars['Int']['output']>;
  /** 의사선생님 아이디 */
  doctorId?: Maybe<Scalars['ID']['output']>;
  /** 연계 이벤트 ID */
  eventId?: Maybe<Scalars['ID']['output']>;
  /** 사진유무 */
  existImages?: Maybe<Scalars['Boolean']['output']>;
  /** 시설/청결도 */
  facilityScore?: Maybe<Scalars['Int']['output']>;
  /** 노출 off 일시 */
  hiddenAt?: Maybe<Scalars['DateTime']['output']>;
  /** 연계 병원 ID */
  hospitalId?: Maybe<Scalars['ID']['output']>;
  /** ID */
  id: Scalars['ID']['output'];
  /** 사진 */
  images?: Maybe<Array<Scalars['String']['output']>>;
  /** 인증/비인증 */
  isVisited?: Maybe<Scalars['Boolean']['output']>;
  /** 친절도 */
  kindnessScore?: Maybe<Scalars['Int']['output']>;
  /** 좋아요 개수 */
  likesCount?: Maybe<Scalars['Int']['output']>;
  /** 연계 약국 ID */
  pharmacyId?: Maybe<Scalars['ID']['output']>;
  /** 평점 */
  rating?: Maybe<Scalars['Int']['output']>;
  /** 연계 접수 ID */
  receiptId?: Maybe<Scalars['ID']['output']>;
  /** 재방문여부 */
  recommendation?: Maybe<Scalars['Boolean']['output']>;
  /** 병원, 약국, 클리닉시술, 이벤트, 비대면 리뷰로 구분 */
  reviewType: ReviewTypeEnum;
  /** 리뷰 작성 경로 */
  source?: Maybe<ReviewSourceEnum>;
  /** 연계 리뷰 ID */
  targetId: Scalars['ID']['output'];
  /** 연계 클리닉시술 ID */
  treatmentId?: Maybe<Scalars['ID']['output']>;
  /** 진료항목 */
  treatmentName?: Maybe<Scalars['String']['output']>;
  /** 진료비용 */
  treatmentPrice?: Maybe<Scalars['Int']['output']>;
  /** 연계 비대면진료 ID */
  untactTreatmentId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** 작성자이메일 */
  userEmail?: Maybe<Scalars['String']['output']>;
  /** 작성한 유저아이디 */
  userId?: Maybe<Scalars['ID']['output']>;
  /** 작성자 */
  userName: Scalars['String']['output'];
  /** 리뷰 노출 */
  visibility: Scalars['Boolean']['output'];
  /** 방문일 */
  visitedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum ReviewSourceEnum {
  Appointment = 'appointment',
  Event = 'event',
  HospitalDetail = 'hospitalDetail',
  NewEvent = 'newEvent',
  PharmacyDetail = 'pharmacyDetail',
  Receipt = 'receipt',
  UntactTreatment = 'untactTreatment',
}

export enum ReviewTypeEnum {
  Event = 'event',
  Hospital = 'hospital',
  Pharmacy = 'pharmacy',
  Tablet = 'tablet',
  UntactDoctor = 'untactDoctor',
}

/** 리뷰 목록 Object */
export type ReviewsOutput = {
  __typename?: 'ReviewsOutput';
  /** 리뷰 목록 */
  list: Array<ReviewObject>;
  /** 총 개수 */
  totalCount: Scalars['Int']['output'];
};

export enum ServiceTypeEnum {
  Clinic = 'clinic',
  Clinic2dp = 'clinic2dp',
  Clinic3dp = 'clinic3dp',
  Hospital = 'hospital',
  Untact = 'untact',
}

export enum SexEnum {
  Female = 'female',
  Male = 'male',
  Unknown = 'unknown',
}

export type SimpleMedicalServiceSummaryOutput = {
  __typename?: 'SimpleMedicalServiceSummaryOutput';
  address?: Maybe<Scalars['String']['output']>;
  clinicEventName?: Maybe<Scalars['String']['output']>;
  clinicScore: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** 병원의 의사 목록 */
  doctors: Array<Scalars['JSONObject']['output']>;
  endAt?: Maybe<Scalars['DateTime']['output']>;
  hospitalName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  mainDoctorEducation?: Maybe<Scalars['JSONObject']['output']>;
  medicalCategoryNames: Array<Scalars['String']['output']>;
  medicalServiceId: Scalars['String']['output'];
  openedDate?: Maybe<Scalars['String']['output']>;
  ratingMean: Scalars['Float']['output'];
  reviewsCount: Scalars['Int']['output'];
  startAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export enum StatusEnum {
  Failed = 'failed',
  Pending = 'pending',
  Success = 'success',
}

export enum TransactionCodeEnum {
  Duplicate = 'duplicate',
  LongTermAbsence = 'longTermAbsence',
  Missing = 'missing',
  NotOwner = 'notOwner',
}

export type BookmarksQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
}>;

export type BookmarksQuery = {
  __typename?: 'Query';
  bookmarks: {
    __typename?: 'ClinicBookMarksOutput';
    totalCount: number;
    list: Array<{
      __typename?: 'ClinicBookMarkOutput';
      id: string;
      createdAt: any;
      selected: boolean;
      clinicEvent: {
        __typename?: 'ClinicEventWithHospitalOutput';
        id: string;
        title: string;
        price: number;
        status: ClinicEventStatusEnum;
        thumbnailImage?: string | null;
        originalPrice: number;
        startAt: any;
        endAt: any;
        allowGoodocAd?: boolean | null;
        hospital?: { __typename?: 'HospitalObject'; id: string; name: string; address?: string | null } | null;
      };
    }>;
  };
};

export type BookmarkClinicEventIdsQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
}>;

export type BookmarkClinicEventIdsQuery = {
  __typename?: 'Query';
  bookmarks: {
    __typename?: 'ClinicBookMarksOutput';
    totalCount: number;
    list: Array<{ __typename?: 'ClinicBookMarkOutput'; id: string; clinicEventId: string }>;
  };
};

export type ToggleBookmarkMutationVariables = Exact<{
  eventId: Scalars['ID']['input'];
  value: Scalars['Boolean']['input'];
}>;

export type ToggleBookmarkMutation = {
  __typename?: 'Mutation';
  toggleBookmark: {
    __typename?: 'ClinicBookmarkObject';
    id: string;
    selected: boolean;
    clinicEventId: string;
    hospitalId?: string | null;
  };
};

export type IsBookmarkedQueryVariables = Exact<{
  eventId: Scalars['ID']['input'];
}>;

export type IsBookmarkedQuery = { __typename?: 'Query'; isBookmarked: boolean };

export type CreateCallRequestMutationVariables = Exact<{
  data: CallRequestInput;
}>;

export type CreateCallRequestMutation = {
  __typename?: 'Mutation';
  createCallRequest: {
    __typename?: 'CallRequestObject';
    id: string;
    createdAt: any;
    updatedAt: any;
    clinicEventId: string;
    userId: string;
    funnel: string;
    babiEventId: string;
    babiClientId: string;
    channel: ChannelTypeEnum;
    dbPrice: number;
  };
};

export type MyCallRequestsQueryVariables = Exact<{
  condition?: InputMaybe<MyCallRequestsInput>;
  pagination: Pagination;
}>;

export type MyCallRequestsQuery = {
  __typename?: 'Query';
  myCallRequests: {
    __typename?: 'CallRequestsOutput';
    totalCount: number;
    list: Array<{
      __typename?: 'CallRequestOutput';
      id: string;
      createdAt: any;
      clinicEventId: string;
      clinicEvent?: {
        __typename?: 'ClinicEventWithHospital';
        id: string;
        title: string;
        subTitle: string;
        target: string;
        thumbnailImage?: string | null;
        hospital?: {
          __typename?: 'HospitalObject';
          id: string;
          name: string;
          address?: string | null;
          latitude?: number | null;
          longitude?: number | null;
        } | null;
      } | null;
    }>;
  };
};

export type ClinicEventQueryVariables = Exact<{
  eventId: Scalars['String']['input'];
}>;

export type ClinicEventQuery = {
  __typename?: 'Query';
  clinicEvent: {
    __typename?: 'ClinicEventWithHospitalOutput';
    id: string;
    status: ClinicEventStatusEnum;
    subTitle: string;
    target: string;
    title: string;
    price: number;
    dbPrice: number;
    thumbnailImage?: string | null;
    hospitalId?: string | null;
    createdAt: any;
    updatedAt: any;
    allowGoodocAd?: boolean | null;
    startAt: any;
    endAt: any;
    deletedAt?: any | null;
    hospital?: {
      __typename?: 'HospitalObject';
      id: string;
      name: string;
      address?: string | null;
      phone?: string | null;
      openedDate?: string | null;
      visibility: boolean;
      reviewVisibility: boolean;
    } | null;
  };
};

export type EventsByIdsQueryVariables = Exact<{
  eventIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;

export type EventsByIdsQuery = {
  __typename?: 'Query';
  eventsByIds: Array<{
    __typename?: 'ClinicEventWithHospitalOutput';
    id: string;
    status: ClinicEventStatusEnum;
    subTitle: string;
    target: string;
    title: string;
    price: number;
    dbPrice: number;
    thumbnailImage?: string | null;
    originalPrice: number;
    startAt: any;
    endAt: any;
    hospital?: {
      __typename?: 'HospitalObject';
      id: string;
      name: string;
      operatingState: ClinicHospitalOperatingStateEnum;
      latitude?: number | null;
      longitude?: number | null;
    } | null;
  }>;
};

export type ClinicEventWithSimilarEventQueryVariables = Exact<{
  eventId: Scalars['String']['input'];
}>;

export type ClinicEventWithSimilarEventQuery = {
  __typename?: 'Query';
  clinicEvent: {
    __typename?: 'ClinicEventWithHospitalOutput';
    id: string;
    status: ClinicEventStatusEnum;
    title: string;
    subTitle: string;
    originalPrice: number;
    price: number;
    dbPrice: number;
    thumbnailImage?: string | null;
    contentImage: string;
    target: string;
    sideEffect: string;
    startAt: any;
    endAt: any;
    allowGoodocAd?: boolean | null;
    allowExternalAd?: boolean | null;
    hospital?: {
      __typename?: 'HospitalObject';
      id: string;
      name: string;
      address?: string | null;
      phone?: string | null;
      reviewVisibility: boolean;
      latitude?: number | null;
      longitude?: number | null;
    } | null;
  };
  similarEvents: Array<{
    __typename?: 'ClinicEventWithHospitalOutput';
    id: string;
    status: ClinicEventStatusEnum;
    title: string;
    subTitle: string;
    originalPrice: number;
    price: number;
    thumbnailImage?: string | null;
    startAt: any;
    endAt: any;
    allowGoodocAd?: boolean | null;
    hospital?: { __typename?: 'HospitalObject'; id: string; name: string; address?: string | null } | null;
  }>;
};

export type ClinicEventLightQueryVariables = Exact<{
  eventId: Scalars['String']['input'];
}>;

export type ClinicEventLightQuery = {
  __typename?: 'Query';
  clinicEvent: {
    __typename?: 'ClinicEventWithHospitalOutput';
    id: string;
    status: ClinicEventStatusEnum;
    title: string;
    subTitle: string;
    originalPrice: number;
    price: number;
    thumbnailImage?: string | null;
    contentImage: string;
    target: string;
    sideEffect: string;
    startAt: any;
    endAt: any;
    allowGoodocAd?: boolean | null;
    deletedAt?: any | null;
    hospital?: {
      __typename?: 'HospitalObject';
      id: string;
      name: string;
      address?: string | null;
      phone?: string | null;
      latitude?: number | null;
      longitude?: number | null;
    } | null;
  };
};

export type CampaignCommentsQueryVariables = Exact<{
  pagination: Pagination;
  campaignId: Scalars['String']['input'];
}>;

export type CampaignCommentsQuery = {
  __typename?: 'Query';
  campaignComments: {
    __typename?: 'CampaignCommentOutput';
    totalCount: number;
    data: Array<{
      __typename?: 'CampaignCommentObject';
      id: string;
      createdAt: any;
      userId: string;
      userNickName?: string | null;
      comment: string;
    }>;
  };
};

export type CreateCampaignCommentMutationVariables = Exact<{
  data: CampaignCommentInput;
}>;

export type CreateCampaignCommentMutation = {
  __typename?: 'Mutation';
  createCampaignComment: {
    __typename?: 'CampaignCommentObject';
    id: string;
    createdAt: any;
    userId: string;
    userNickName?: string | null;
    comment: string;
  };
};

export type DeleteCampaignCommentMutationVariables = Exact<{
  commentId: Scalars['String']['input'];
}>;

export type DeleteCampaignCommentMutation = { __typename?: 'Mutation'; deleteCampaignComment: boolean };

export type JsonStorageByKeyQueryVariables = Exact<{
  key: Scalars['ID']['input'];
}>;

export type JsonStorageByKeyQuery = {
  __typename?: 'Query';
  jsonStorageByKey: { __typename?: 'JsonStorageObject'; id: string; key: string; json: Array<any> };
};

export type LocationSearchPresetsQueryVariables = Exact<{ [key: string]: never }>;

export type LocationSearchPresetsQuery = {
  __typename?: 'Query';
  locationSearchPresets: Array<{
    __typename?: 'LocationSearchPresetObject';
    id: string;
    title: string;
    subtitle?: string | null;
    region: string;
    latitude: number;
    longitude: number;
  }>;
};

export type MedicalCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type MedicalCategoriesQuery = {
  __typename?: 'Query';
  list: Array<{
    __typename?: 'MedicalCategoryObject';
    id: string;
    name: string;
    depth: number;
    iconUrl?: string | null;
    secondDepthFilter: boolean;
    sortIndex: number;
    parentId?: number | null;
    chatBotEnabled?: boolean | null;
  }>;
  tree: Array<{
    __typename?: 'MedicalCategory3DepthTreeOutput';
    id: string;
    children: Array<{
      __typename?: 'MedicalCategory2DepthTreeOutput';
      id: string;
      children: Array<{ __typename?: 'MedicalCategoryObject'; id: string }>;
    }>;
  }>;
};

export type MedicalCategoryPropertiesByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type MedicalCategoryPropertiesByIdQuery = {
  __typename?: 'Query';
  medicalCategoryById: {
    __typename?: 'MedicalCategoryOutput';
    id: string;
    name: string;
    iconUrl?: string | null;
    medicalCategoryProperties: Array<{
      __typename?: 'MedicalCategoryPropertiesOutput';
      id: string;
      name: string;
      internalName?: string | null;
      propertyType: CategoryPropertyTypeEnum;
      useDescription: boolean;
      useIcon: boolean;
      sortIndex: number;
      medicalCategoryPropertyValues: Array<{
        __typename?: 'MedicalCategoryPropertyValueObject';
        id: string;
        name: string;
        sortIndex: number;
        origin?: OriginTypeEnum | null;
      }>;
    }>;
  };
};

export type MedicalCurationsQueryVariables = Exact<{ [key: string]: never }>;

export type MedicalCurationsQuery = {
  __typename?: 'Query';
  list: Array<{
    __typename?: 'MedicalCurationJoinMappingAndCategoryOutput';
    id: string;
    name: string;
    curationType: CurationTypeEnum;
    iconUrl?: string | null;
    sortIndex: number;
    medicalCategoryCurationMappings?: Array<{
      __typename?: 'MedicalCategoryCurationMappingObject';
      medicalCategoryId: number;
      sortIndex: number;
    } | null> | null;
  }>;
  curationLocations: Array<{
    __typename?: 'CurationLocationObject';
    id: string;
    name: string;
    latitude: number;
    longitude: number;
    iconUrl?: string | null;
    sortIndex: number;
  }>;
};

export type SummaryFieldFragment = {
  __typename?: 'MedicalServiceSummaryObject';
  clinicEventName?: string | null;
  hospitalName?: string | null;
  ratingMean: number;
  reviewsCount: number;
  address?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  openedDate?: string | null;
  mainDoctorEducation?: any | null;
  medicalCategoryNames: Array<string>;
  priceDetails: Array<any>;
};

export type MedicalServicesByCategoryIdQueryVariables = Exact<{
  input: MedicalServiceByCategoryIdInput;
  pagination: Pagination;
}>;

export type MedicalServicesByCategoryIdQuery = {
  __typename?: 'Query';
  medicalServicesByCategoryId: {
    __typename?: 'MedicalServiceOutput';
    totalCount: number;
    data: Array<{
      __typename?: 'MedicalServiceOutputWithOthers';
      id: string;
      displayName: string;
      url?: string | null;
      thumbnailUrl?: string | null;
      clinicEventId?: string | null;
      hospitalId: string;
      price?: number | null;
      marketPrice: boolean;
      textPrice?: string | null;
      originalPrice?: number | null;
      medicalCategoryId: string;
      actionType: ActionTypeEnum;
      medicalServiceSummary: {
        __typename?: 'MedicalServiceSummaryObject';
        clinicEventName?: string | null;
        hospitalName?: string | null;
        ratingMean: number;
        reviewsCount: number;
        address?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        openedDate?: string | null;
        mainDoctorEducation?: any | null;
        medicalCategoryNames: Array<string>;
        priceDetails: Array<any>;
      };
    }>;
  };
};

export type MedicalServicesByIdsQueryVariables = Exact<{
  input: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type MedicalServicesByIdsQuery = {
  __typename?: 'Query';
  medicalServicesByIds: {
    __typename?: 'MedicalServiceByIdsOutput';
    data: Array<{
      __typename?: 'MedicalServiceByIdsWithOthersOutput';
      id: string;
      displayName: string;
      url?: string | null;
      thumbnailUrl?: string | null;
      clinicEventId?: string | null;
      hospitalId: string;
      price?: number | null;
      marketPrice: boolean;
      textPrice?: string | null;
      originalPrice?: number | null;
      medicalCategoryId: string;
      actionType: ActionTypeEnum;
      medicalServiceSummary: {
        __typename?: 'MedicalServiceSummaryObject';
        clinicEventName?: string | null;
        hospitalName?: string | null;
        ratingMean: number;
        reviewsCount: number;
        address?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        openedDate?: string | null;
        mainDoctorEducation?: any | null;
        medicalCategoryNames: Array<string>;
        priceDetails: Array<any>;
      };
    }>;
  };
};

export type MedicalServicesRankingByCurationIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type MedicalServicesRankingByCurationIdQuery = {
  __typename?: 'Query';
  medicalServicesRankingByCurationId: Array<{
    __typename?: 'MedicalServiceByCurationIdOutput';
    id: string;
    displayName: string;
    url?: string | null;
    marketPrice: boolean;
    price?: number | null;
    originalPrice?: number | null;
    thumbnailUrl?: string | null;
    clinicEventId?: string | null;
    textPrice?: string | null;
    medicalCategoryId: string;
    actionType: ActionTypeEnum;
    hospitalId: string;
    medicalCategory: { __typename?: 'MedicalCategoryObject'; id: string; name: string };
    medicalServiceSummary: {
      __typename?: 'SimpleMedicalServiceSummaryOutput';
      id: string;
      ratingMean: number;
      reviewsCount: number;
      latitude?: number | null;
      longitude?: number | null;
      openedDate?: string | null;
      medicalCategoryNames: Array<string>;
      hospitalName?: string | null;
      clinicEventName?: string | null;
      address?: string | null;
      clinicScore: number;
      mainDoctorEducation?: any | null;
    };
  }>;
};

export type NewMedicalServicesQueryVariables = Exact<{ [key: string]: never }>;

export type NewMedicalServicesQuery = {
  __typename?: 'Query';
  newMedicalServices: Array<{
    __typename?: 'MedicalServiceByIdsWithOthersOutput';
    id: string;
    displayName: string;
    url?: string | null;
    thumbnailUrl?: string | null;
    clinicEventId?: string | null;
    hospitalId: string;
    price?: number | null;
    marketPrice: boolean;
    textPrice?: string | null;
    originalPrice?: number | null;
    medicalCategoryId: string;
    actionType: ActionTypeEnum;
    medicalServiceSummary: {
      __typename?: 'MedicalServiceSummaryObject';
      clinicEventName?: string | null;
      hospitalName?: string | null;
      ratingMean: number;
      reviewsCount: number;
      address?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      openedDate?: string | null;
      mainDoctorEducation?: any | null;
      medicalCategoryNames: Array<string>;
      priceDetails: Array<any>;
    };
  }>;
};

export type NewMedicalServicesWithPaginationQueryVariables = Exact<{
  pagination: Pagination;
}>;

export type NewMedicalServicesWithPaginationQuery = {
  __typename?: 'Query';
  newMedicalServicesWithPagination: {
    __typename?: 'MedicalServiceByIdsAndCountOutput';
    totalCount: number;
    data: Array<{
      __typename?: 'MedicalServiceByIdsWithOthersOutput';
      id: string;
      displayName: string;
      actionType: ActionTypeEnum;
      url?: string | null;
      thumbnailUrl?: string | null;
      clinicEventId?: string | null;
      hospitalId: string;
      price?: number | null;
      marketPrice: boolean;
      textPrice?: string | null;
      originalPrice?: number | null;
      medicalCategoryId: string;
      medicalServiceSummary: {
        __typename?: 'MedicalServiceSummaryObject';
        clinicEventName?: string | null;
        hospitalName?: string | null;
        ratingMean: number;
        reviewsCount: number;
        address?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        openedDate?: string | null;
        mainDoctorEducation?: any | null;
        medicalCategoryNames: Array<string>;
        priceDetails: Array<any>;
      };
    }>;
  };
};

export type MedicalServiceByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  hospitalId: Scalars['ID']['input'];
}>;

export type MedicalServiceByIdQuery = {
  __typename?: 'Query';
  medicalServiceById: {
    __typename?: 'MedicalServiceByIdOutput';
    id: string;
    displayName: string;
    url?: string | null;
    thumbnailUrl?: string | null;
    clinicEventId?: string | null;
    hospitalId: string;
    price?: number | null;
    marketPrice: boolean;
    textPrice?: string | null;
    originalPrice?: number | null;
    medicalCategoryId: string;
    medicalServiceSummary: {
      __typename?: 'MedicalServiceSummaryObject';
      clinicEventName?: string | null;
      hospitalName?: string | null;
      ratingMean: number;
      reviewsCount: number;
      address?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      openedDate?: string | null;
      mainDoctorEducation?: any | null;
      medicalCategoryNames: Array<string>;
      priceDetails: Array<any>;
    };
    hospital: {
      __typename?: 'HospitalObject';
      id: string;
      name: string;
      address?: string | null;
      hospitalOperationTimes?: string | null;
    };
  };
  hospitalOperationTimesByHospitalId: Array<{
    __typename?: 'HospitalOperationObject';
    id: string;
    operationType: HospitalOperationTimeTypeEnum;
    from: string;
    to: string;
    operationOff: boolean;
    hospitalId: string;
    targetDate?: any | null;
    holidayName?: string | null;
  }>;
};

export type PopularMedicalServicesQueryVariables = Exact<{ [key: string]: never }>;

export type PopularMedicalServicesQuery = {
  __typename?: 'Query';
  popularMedicalServices: Array<{
    __typename?: 'PopularMedicalServiceObject';
    id: string;
    name: string;
    path: string;
    rank: number;
  }>;
};

export type CreateReservationMutationVariables = Exact<{
  data: ReservationHistoryInput;
}>;

export type CreateReservationMutation = {
  __typename?: 'Mutation';
  createReservation: {
    __typename?: 'ReservationHistoryObject';
    id: string;
    medicalServiceId: string;
    hospitalId: string;
    userId: string;
    reservationAt: any;
    name: string;
    phone: string;
    birth?: string | null;
    sex?: SexEnum | null;
    status: ReservationStatusEnum;
  };
};

export type MainBestReviewsQueryVariables = Exact<{ [key: string]: never }>;

export type MainBestReviewsQuery = {
  __typename?: 'Query';
  mainBestReviews: Array<{
    __typename?: 'ReviewAtMainObject';
    id: string;
    images?: Array<string> | null;
    contents?: string | null;
    rating?: number | null;
    createdAt: any;
    hospitalId?: string | null;
    hospitalName: string;
    medicalCategoryId: string;
    medicalCategoryName: string;
    clinicEventName: string;
    lacteaReviewId: string;
    clinicEventId: string;
  }>;
};

export type ReviewFragment = {
  __typename?: 'ReviewAtMainObject';
  id: string;
  images?: Array<string> | null;
  contents?: string | null;
  rating?: number | null;
  createdAt: any;
  hospitalId?: string | null;
  hospitalName: string;
  medicalCategoryId: string;
  medicalCategoryName: string;
  clinicEventName: string;
  lacteaReviewId: string;
  clinicEventId: string;
};

export const SummaryFieldFragmentDoc = gql`
  fragment summaryField on MedicalServiceSummaryObject {
    clinicEventName
    hospitalName
    ratingMean
    reviewsCount
    address
    latitude
    longitude
    openedDate
    mainDoctorEducation
    medicalCategoryNames
    priceDetails
  }
`;
export const ReviewFragmentDoc = gql`
  fragment review on ReviewAtMainObject {
    id
    images
    contents
    rating
    createdAt
    hospitalId
    hospitalName
    medicalCategoryId
    medicalCategoryName
    clinicEventName
    lacteaReviewId
    clinicEventId
  }
`;
export const BookmarksDocument = gql`
  query bookmarks($pagination: Pagination) {
    bookmarks(pagination: $pagination) {
      totalCount
      list {
        id
        createdAt
        selected
        clinicEvent {
          id
          title
          price
          status
          thumbnailImage
          originalPrice
          price
          startAt
          endAt
          allowGoodocAd
          hospital {
            id
            name
            address
          }
        }
      }
    }
  }
`;

/**
 * __useBookmarksQuery__
 *
 * To run a query within a React component, call `useBookmarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookmarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookmarksQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useBookmarksQuery(baseOptions?: Apollo.QueryHookOptions<BookmarksQuery, BookmarksQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BookmarksQuery, BookmarksQueryVariables>(BookmarksDocument, options);
}
export function useBookmarksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BookmarksQuery, BookmarksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BookmarksQuery, BookmarksQueryVariables>(BookmarksDocument, options);
}
export type BookmarksQueryHookResult = ReturnType<typeof useBookmarksQuery>;
export type BookmarksLazyQueryHookResult = ReturnType<typeof useBookmarksLazyQuery>;
export type BookmarksQueryResult = Apollo.QueryResult<BookmarksQuery, BookmarksQueryVariables>;
export const BookmarkClinicEventIdsDocument = gql`
  query bookmarkClinicEventIds($pagination: Pagination) {
    bookmarks(pagination: $pagination) {
      totalCount
      list {
        id
        clinicEventId
      }
    }
  }
`;

/**
 * __useBookmarkClinicEventIdsQuery__
 *
 * To run a query within a React component, call `useBookmarkClinicEventIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookmarkClinicEventIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookmarkClinicEventIdsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useBookmarkClinicEventIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<BookmarkClinicEventIdsQuery, BookmarkClinicEventIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BookmarkClinicEventIdsQuery, BookmarkClinicEventIdsQueryVariables>(
    BookmarkClinicEventIdsDocument,
    options
  );
}
export function useBookmarkClinicEventIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BookmarkClinicEventIdsQuery, BookmarkClinicEventIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BookmarkClinicEventIdsQuery, BookmarkClinicEventIdsQueryVariables>(
    BookmarkClinicEventIdsDocument,
    options
  );
}
export type BookmarkClinicEventIdsQueryHookResult = ReturnType<typeof useBookmarkClinicEventIdsQuery>;
export type BookmarkClinicEventIdsLazyQueryHookResult = ReturnType<typeof useBookmarkClinicEventIdsLazyQuery>;
export type BookmarkClinicEventIdsQueryResult = Apollo.QueryResult<
  BookmarkClinicEventIdsQuery,
  BookmarkClinicEventIdsQueryVariables
>;
export const ToggleBookmarkDocument = gql`
  mutation toggleBookmark($eventId: ID!, $value: Boolean!) {
    toggleBookmark(eventId: $eventId, value: $value) {
      id
      selected
      clinicEventId
      hospitalId
    }
  }
`;
export type ToggleBookmarkMutationFn = Apollo.MutationFunction<ToggleBookmarkMutation, ToggleBookmarkMutationVariables>;

/**
 * __useToggleBookmarkMutation__
 *
 * To run a mutation, you first call `useToggleBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleBookmarkMutation, { data, loading, error }] = useToggleBookmarkMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useToggleBookmarkMutation(
  baseOptions?: Apollo.MutationHookOptions<ToggleBookmarkMutation, ToggleBookmarkMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ToggleBookmarkMutation, ToggleBookmarkMutationVariables>(ToggleBookmarkDocument, options);
}
export type ToggleBookmarkMutationHookResult = ReturnType<typeof useToggleBookmarkMutation>;
export type ToggleBookmarkMutationResult = Apollo.MutationResult<ToggleBookmarkMutation>;
export type ToggleBookmarkMutationOptions = Apollo.BaseMutationOptions<
  ToggleBookmarkMutation,
  ToggleBookmarkMutationVariables
>;
export const IsBookmarkedDocument = gql`
  query isBookmarked($eventId: ID!) {
    isBookmarked(eventId: $eventId)
  }
`;

/**
 * __useIsBookmarkedQuery__
 *
 * To run a query within a React component, call `useIsBookmarkedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsBookmarkedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsBookmarkedQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useIsBookmarkedQuery(
  baseOptions: Apollo.QueryHookOptions<IsBookmarkedQuery, IsBookmarkedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IsBookmarkedQuery, IsBookmarkedQueryVariables>(IsBookmarkedDocument, options);
}
export function useIsBookmarkedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IsBookmarkedQuery, IsBookmarkedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IsBookmarkedQuery, IsBookmarkedQueryVariables>(IsBookmarkedDocument, options);
}
export type IsBookmarkedQueryHookResult = ReturnType<typeof useIsBookmarkedQuery>;
export type IsBookmarkedLazyQueryHookResult = ReturnType<typeof useIsBookmarkedLazyQuery>;
export type IsBookmarkedQueryResult = Apollo.QueryResult<IsBookmarkedQuery, IsBookmarkedQueryVariables>;
export const CreateCallRequestDocument = gql`
  mutation createCallRequest($data: CallRequestInput!) {
    createCallRequest(data: $data) {
      id
      createdAt
      updatedAt
      clinicEventId
      userId
      funnel
      babiEventId
      babiClientId
      channel
      dbPrice
    }
  }
`;
export type CreateCallRequestMutationFn = Apollo.MutationFunction<
  CreateCallRequestMutation,
  CreateCallRequestMutationVariables
>;

/**
 * __useCreateCallRequestMutation__
 *
 * To run a mutation, you first call `useCreateCallRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCallRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCallRequestMutation, { data, loading, error }] = useCreateCallRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCallRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCallRequestMutation, CreateCallRequestMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCallRequestMutation, CreateCallRequestMutationVariables>(
    CreateCallRequestDocument,
    options
  );
}
export type CreateCallRequestMutationHookResult = ReturnType<typeof useCreateCallRequestMutation>;
export type CreateCallRequestMutationResult = Apollo.MutationResult<CreateCallRequestMutation>;
export type CreateCallRequestMutationOptions = Apollo.BaseMutationOptions<
  CreateCallRequestMutation,
  CreateCallRequestMutationVariables
>;
export const MyCallRequestsDocument = gql`
  query myCallRequests($condition: MyCallRequestsInput, $pagination: Pagination!) {
    myCallRequests(condition: $condition, pagination: $pagination) {
      totalCount
      list {
        id
        createdAt
        clinicEventId
        clinicEvent {
          id
          title
          subTitle
          target
          thumbnailImage
          hospital {
            id
            name
            address
            latitude
            longitude
          }
        }
      }
    }
  }
`;

/**
 * __useMyCallRequestsQuery__
 *
 * To run a query within a React component, call `useMyCallRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyCallRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyCallRequestsQuery({
 *   variables: {
 *      condition: // value for 'condition'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useMyCallRequestsQuery(
  baseOptions: Apollo.QueryHookOptions<MyCallRequestsQuery, MyCallRequestsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyCallRequestsQuery, MyCallRequestsQueryVariables>(MyCallRequestsDocument, options);
}
export function useMyCallRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyCallRequestsQuery, MyCallRequestsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyCallRequestsQuery, MyCallRequestsQueryVariables>(MyCallRequestsDocument, options);
}
export type MyCallRequestsQueryHookResult = ReturnType<typeof useMyCallRequestsQuery>;
export type MyCallRequestsLazyQueryHookResult = ReturnType<typeof useMyCallRequestsLazyQuery>;
export type MyCallRequestsQueryResult = Apollo.QueryResult<MyCallRequestsQuery, MyCallRequestsQueryVariables>;
export const ClinicEventDocument = gql`
  query clinicEvent($eventId: String!) {
    clinicEvent(eventId: $eventId) {
      id
      status
      subTitle
      target
      title
      price
      dbPrice
      thumbnailImage
      hospitalId
      createdAt
      updatedAt
      allowGoodocAd
      startAt
      endAt
      deletedAt
      hospital {
        id
        name
        address
        phone
        openedDate
        visibility
        reviewVisibility
      }
    }
  }
`;

/**
 * __useClinicEventQuery__
 *
 * To run a query within a React component, call `useClinicEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useClinicEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClinicEventQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useClinicEventQuery(baseOptions: Apollo.QueryHookOptions<ClinicEventQuery, ClinicEventQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClinicEventQuery, ClinicEventQueryVariables>(ClinicEventDocument, options);
}
export function useClinicEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ClinicEventQuery, ClinicEventQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClinicEventQuery, ClinicEventQueryVariables>(ClinicEventDocument, options);
}
export type ClinicEventQueryHookResult = ReturnType<typeof useClinicEventQuery>;
export type ClinicEventLazyQueryHookResult = ReturnType<typeof useClinicEventLazyQuery>;
export type ClinicEventQueryResult = Apollo.QueryResult<ClinicEventQuery, ClinicEventQueryVariables>;
export const EventsByIdsDocument = gql`
  query eventsByIds($eventIds: [ID!]) {
    eventsByIds(eventIds: $eventIds) {
      id
      status
      subTitle
      target
      title
      price
      dbPrice
      thumbnailImage
      originalPrice
      startAt
      endAt
      hospital {
        id
        name
        operatingState
        latitude
        longitude
      }
    }
  }
`;

/**
 * __useEventsByIdsQuery__
 *
 * To run a query within a React component, call `useEventsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsByIdsQuery({
 *   variables: {
 *      eventIds: // value for 'eventIds'
 *   },
 * });
 */
export function useEventsByIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<EventsByIdsQuery, EventsByIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EventsByIdsQuery, EventsByIdsQueryVariables>(EventsByIdsDocument, options);
}
export function useEventsByIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EventsByIdsQuery, EventsByIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EventsByIdsQuery, EventsByIdsQueryVariables>(EventsByIdsDocument, options);
}
export type EventsByIdsQueryHookResult = ReturnType<typeof useEventsByIdsQuery>;
export type EventsByIdsLazyQueryHookResult = ReturnType<typeof useEventsByIdsLazyQuery>;
export type EventsByIdsQueryResult = Apollo.QueryResult<EventsByIdsQuery, EventsByIdsQueryVariables>;
export const ClinicEventWithSimilarEventDocument = gql`
  query clinicEventWithSimilarEvent($eventId: String!) {
    clinicEvent(eventId: $eventId) {
      id
      status
      title
      subTitle
      originalPrice
      price
      dbPrice
      thumbnailImage
      contentImage
      target
      sideEffect
      startAt
      endAt
      allowGoodocAd
      allowExternalAd
      hospital {
        id
        name
        address
        phone
        reviewVisibility
        latitude
        longitude
      }
    }
    similarEvents(eventId: $eventId) {
      id
      status
      title
      subTitle
      originalPrice
      price
      thumbnailImage
      startAt
      endAt
      allowGoodocAd
      hospital {
        id
        name
        address
      }
    }
  }
`;

/**
 * __useClinicEventWithSimilarEventQuery__
 *
 * To run a query within a React component, call `useClinicEventWithSimilarEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useClinicEventWithSimilarEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClinicEventWithSimilarEventQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useClinicEventWithSimilarEventQuery(
  baseOptions: Apollo.QueryHookOptions<ClinicEventWithSimilarEventQuery, ClinicEventWithSimilarEventQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClinicEventWithSimilarEventQuery, ClinicEventWithSimilarEventQueryVariables>(
    ClinicEventWithSimilarEventDocument,
    options
  );
}
export function useClinicEventWithSimilarEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ClinicEventWithSimilarEventQuery, ClinicEventWithSimilarEventQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClinicEventWithSimilarEventQuery, ClinicEventWithSimilarEventQueryVariables>(
    ClinicEventWithSimilarEventDocument,
    options
  );
}
export type ClinicEventWithSimilarEventQueryHookResult = ReturnType<typeof useClinicEventWithSimilarEventQuery>;
export type ClinicEventWithSimilarEventLazyQueryHookResult = ReturnType<typeof useClinicEventWithSimilarEventLazyQuery>;
export type ClinicEventWithSimilarEventQueryResult = Apollo.QueryResult<
  ClinicEventWithSimilarEventQuery,
  ClinicEventWithSimilarEventQueryVariables
>;
export const ClinicEventLightDocument = gql`
  query clinicEventLight($eventId: String!) {
    clinicEvent(eventId: $eventId) {
      id
      status
      title
      subTitle
      originalPrice
      price
      thumbnailImage
      contentImage
      target
      sideEffect
      startAt
      endAt
      allowGoodocAd
      deletedAt
      hospital {
        id
        name
        address
        phone
        latitude
        longitude
      }
    }
  }
`;

/**
 * __useClinicEventLightQuery__
 *
 * To run a query within a React component, call `useClinicEventLightQuery` and pass it any options that fit your needs.
 * When your component renders, `useClinicEventLightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClinicEventLightQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useClinicEventLightQuery(
  baseOptions: Apollo.QueryHookOptions<ClinicEventLightQuery, ClinicEventLightQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClinicEventLightQuery, ClinicEventLightQueryVariables>(ClinicEventLightDocument, options);
}
export function useClinicEventLightLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ClinicEventLightQuery, ClinicEventLightQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClinicEventLightQuery, ClinicEventLightQueryVariables>(ClinicEventLightDocument, options);
}
export type ClinicEventLightQueryHookResult = ReturnType<typeof useClinicEventLightQuery>;
export type ClinicEventLightLazyQueryHookResult = ReturnType<typeof useClinicEventLightLazyQuery>;
export type ClinicEventLightQueryResult = Apollo.QueryResult<ClinicEventLightQuery, ClinicEventLightQueryVariables>;
export const CampaignCommentsDocument = gql`
  query campaignComments($pagination: Pagination!, $campaignId: String!) {
    campaignComments(pagination: $pagination, campaignId: $campaignId) {
      totalCount
      data {
        id
        createdAt
        userId
        userNickName
        comment
      }
    }
  }
`;

/**
 * __useCampaignCommentsQuery__
 *
 * To run a query within a React component, call `useCampaignCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignCommentsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useCampaignCommentsQuery(
  baseOptions: Apollo.QueryHookOptions<CampaignCommentsQuery, CampaignCommentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CampaignCommentsQuery, CampaignCommentsQueryVariables>(CampaignCommentsDocument, options);
}
export function useCampaignCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CampaignCommentsQuery, CampaignCommentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CampaignCommentsQuery, CampaignCommentsQueryVariables>(CampaignCommentsDocument, options);
}
export type CampaignCommentsQueryHookResult = ReturnType<typeof useCampaignCommentsQuery>;
export type CampaignCommentsLazyQueryHookResult = ReturnType<typeof useCampaignCommentsLazyQuery>;
export type CampaignCommentsQueryResult = Apollo.QueryResult<CampaignCommentsQuery, CampaignCommentsQueryVariables>;
export const CreateCampaignCommentDocument = gql`
  mutation createCampaignComment($data: CampaignCommentInput!) {
    createCampaignComment(data: $data) {
      id
      createdAt
      userId
      userNickName
      comment
    }
  }
`;
export type CreateCampaignCommentMutationFn = Apollo.MutationFunction<
  CreateCampaignCommentMutation,
  CreateCampaignCommentMutationVariables
>;

/**
 * __useCreateCampaignCommentMutation__
 *
 * To run a mutation, you first call `useCreateCampaignCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCampaignCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCampaignCommentMutation, { data, loading, error }] = useCreateCampaignCommentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCampaignCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCampaignCommentMutation, CreateCampaignCommentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCampaignCommentMutation, CreateCampaignCommentMutationVariables>(
    CreateCampaignCommentDocument,
    options
  );
}
export type CreateCampaignCommentMutationHookResult = ReturnType<typeof useCreateCampaignCommentMutation>;
export type CreateCampaignCommentMutationResult = Apollo.MutationResult<CreateCampaignCommentMutation>;
export type CreateCampaignCommentMutationOptions = Apollo.BaseMutationOptions<
  CreateCampaignCommentMutation,
  CreateCampaignCommentMutationVariables
>;
export const DeleteCampaignCommentDocument = gql`
  mutation deleteCampaignComment($commentId: String!) {
    deleteCampaignComment(commentId: $commentId)
  }
`;
export type DeleteCampaignCommentMutationFn = Apollo.MutationFunction<
  DeleteCampaignCommentMutation,
  DeleteCampaignCommentMutationVariables
>;

/**
 * __useDeleteCampaignCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCampaignCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCampaignCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCampaignCommentMutation, { data, loading, error }] = useDeleteCampaignCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useDeleteCampaignCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCampaignCommentMutation, DeleteCampaignCommentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCampaignCommentMutation, DeleteCampaignCommentMutationVariables>(
    DeleteCampaignCommentDocument,
    options
  );
}
export type DeleteCampaignCommentMutationHookResult = ReturnType<typeof useDeleteCampaignCommentMutation>;
export type DeleteCampaignCommentMutationResult = Apollo.MutationResult<DeleteCampaignCommentMutation>;
export type DeleteCampaignCommentMutationOptions = Apollo.BaseMutationOptions<
  DeleteCampaignCommentMutation,
  DeleteCampaignCommentMutationVariables
>;
export const JsonStorageByKeyDocument = gql`
  query jsonStorageByKey($key: ID!) {
    jsonStorageByKey(key: $key) {
      id
      key
      json
    }
  }
`;

/**
 * __useJsonStorageByKeyQuery__
 *
 * To run a query within a React component, call `useJsonStorageByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useJsonStorageByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJsonStorageByKeyQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useJsonStorageByKeyQuery(
  baseOptions: Apollo.QueryHookOptions<JsonStorageByKeyQuery, JsonStorageByKeyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<JsonStorageByKeyQuery, JsonStorageByKeyQueryVariables>(JsonStorageByKeyDocument, options);
}
export function useJsonStorageByKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<JsonStorageByKeyQuery, JsonStorageByKeyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<JsonStorageByKeyQuery, JsonStorageByKeyQueryVariables>(JsonStorageByKeyDocument, options);
}
export type JsonStorageByKeyQueryHookResult = ReturnType<typeof useJsonStorageByKeyQuery>;
export type JsonStorageByKeyLazyQueryHookResult = ReturnType<typeof useJsonStorageByKeyLazyQuery>;
export type JsonStorageByKeyQueryResult = Apollo.QueryResult<JsonStorageByKeyQuery, JsonStorageByKeyQueryVariables>;
export const LocationSearchPresetsDocument = gql`
  query locationSearchPresets {
    locationSearchPresets {
      id
      title
      subtitle
      region
      latitude
      longitude
    }
  }
`;

/**
 * __useLocationSearchPresetsQuery__
 *
 * To run a query within a React component, call `useLocationSearchPresetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationSearchPresetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationSearchPresetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLocationSearchPresetsQuery(
  baseOptions?: Apollo.QueryHookOptions<LocationSearchPresetsQuery, LocationSearchPresetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LocationSearchPresetsQuery, LocationSearchPresetsQueryVariables>(
    LocationSearchPresetsDocument,
    options
  );
}
export function useLocationSearchPresetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LocationSearchPresetsQuery, LocationSearchPresetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LocationSearchPresetsQuery, LocationSearchPresetsQueryVariables>(
    LocationSearchPresetsDocument,
    options
  );
}
export type LocationSearchPresetsQueryHookResult = ReturnType<typeof useLocationSearchPresetsQuery>;
export type LocationSearchPresetsLazyQueryHookResult = ReturnType<typeof useLocationSearchPresetsLazyQuery>;
export type LocationSearchPresetsQueryResult = Apollo.QueryResult<
  LocationSearchPresetsQuery,
  LocationSearchPresetsQueryVariables
>;
export const MedicalCategoriesDocument = gql`
  query medicalCategories {
    list: medicalCategories {
      id
      name
      depth
      iconUrl
      secondDepthFilter
      sortIndex
      parentId
      chatBotEnabled
    }
    tree: medicalCategoryTree {
      id
      children {
        id
        children {
          id
        }
      }
    }
  }
`;

/**
 * __useMedicalCategoriesQuery__
 *
 * To run a query within a React component, call `useMedicalCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicalCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicalCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMedicalCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<MedicalCategoriesQuery, MedicalCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MedicalCategoriesQuery, MedicalCategoriesQueryVariables>(MedicalCategoriesDocument, options);
}
export function useMedicalCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MedicalCategoriesQuery, MedicalCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MedicalCategoriesQuery, MedicalCategoriesQueryVariables>(
    MedicalCategoriesDocument,
    options
  );
}
export type MedicalCategoriesQueryHookResult = ReturnType<typeof useMedicalCategoriesQuery>;
export type MedicalCategoriesLazyQueryHookResult = ReturnType<typeof useMedicalCategoriesLazyQuery>;
export type MedicalCategoriesQueryResult = Apollo.QueryResult<MedicalCategoriesQuery, MedicalCategoriesQueryVariables>;
export const MedicalCategoryPropertiesByIdDocument = gql`
  query medicalCategoryPropertiesById($id: ID!) {
    medicalCategoryById(id: $id) {
      id
      name
      iconUrl
      medicalCategoryProperties {
        id
        name
        internalName
        propertyType
        useDescription
        useIcon
        propertyType
        sortIndex
        medicalCategoryPropertyValues {
          id
          name
          sortIndex
          origin
        }
      }
    }
  }
`;

/**
 * __useMedicalCategoryPropertiesByIdQuery__
 *
 * To run a query within a React component, call `useMedicalCategoryPropertiesByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicalCategoryPropertiesByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicalCategoryPropertiesByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMedicalCategoryPropertiesByIdQuery(
  baseOptions: Apollo.QueryHookOptions<MedicalCategoryPropertiesByIdQuery, MedicalCategoryPropertiesByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MedicalCategoryPropertiesByIdQuery, MedicalCategoryPropertiesByIdQueryVariables>(
    MedicalCategoryPropertiesByIdDocument,
    options
  );
}
export function useMedicalCategoryPropertiesByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MedicalCategoryPropertiesByIdQuery,
    MedicalCategoryPropertiesByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MedicalCategoryPropertiesByIdQuery, MedicalCategoryPropertiesByIdQueryVariables>(
    MedicalCategoryPropertiesByIdDocument,
    options
  );
}
export type MedicalCategoryPropertiesByIdQueryHookResult = ReturnType<typeof useMedicalCategoryPropertiesByIdQuery>;
export type MedicalCategoryPropertiesByIdLazyQueryHookResult = ReturnType<
  typeof useMedicalCategoryPropertiesByIdLazyQuery
>;
export type MedicalCategoryPropertiesByIdQueryResult = Apollo.QueryResult<
  MedicalCategoryPropertiesByIdQuery,
  MedicalCategoryPropertiesByIdQueryVariables
>;
export const MedicalCurationsDocument = gql`
  query medicalCurations {
    list: medicalCurations {
      id
      name
      curationType
      iconUrl
      sortIndex
      medicalCategoryCurationMappings {
        medicalCategoryId
        sortIndex
      }
    }
    curationLocations {
      id
      name
      latitude
      longitude
      iconUrl
      sortIndex
    }
  }
`;

/**
 * __useMedicalCurationsQuery__
 *
 * To run a query within a React component, call `useMedicalCurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicalCurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicalCurationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMedicalCurationsQuery(
  baseOptions?: Apollo.QueryHookOptions<MedicalCurationsQuery, MedicalCurationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MedicalCurationsQuery, MedicalCurationsQueryVariables>(MedicalCurationsDocument, options);
}
export function useMedicalCurationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MedicalCurationsQuery, MedicalCurationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MedicalCurationsQuery, MedicalCurationsQueryVariables>(MedicalCurationsDocument, options);
}
export type MedicalCurationsQueryHookResult = ReturnType<typeof useMedicalCurationsQuery>;
export type MedicalCurationsLazyQueryHookResult = ReturnType<typeof useMedicalCurationsLazyQuery>;
export type MedicalCurationsQueryResult = Apollo.QueryResult<MedicalCurationsQuery, MedicalCurationsQueryVariables>;
export const MedicalServicesByCategoryIdDocument = gql`
  query medicalServicesByCategoryId($input: MedicalServiceByCategoryIdInput!, $pagination: Pagination!) {
    medicalServicesByCategoryId(input: $input, pagination: $pagination) {
      data {
        id
        displayName
        url
        thumbnailUrl
        clinicEventId
        hospitalId
        price
        marketPrice
        textPrice
        originalPrice
        medicalCategoryId
        actionType
        medicalServiceSummary {
          ...summaryField
        }
      }
      totalCount
    }
  }
  ${SummaryFieldFragmentDoc}
`;

/**
 * __useMedicalServicesByCategoryIdQuery__
 *
 * To run a query within a React component, call `useMedicalServicesByCategoryIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicalServicesByCategoryIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicalServicesByCategoryIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useMedicalServicesByCategoryIdQuery(
  baseOptions: Apollo.QueryHookOptions<MedicalServicesByCategoryIdQuery, MedicalServicesByCategoryIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MedicalServicesByCategoryIdQuery, MedicalServicesByCategoryIdQueryVariables>(
    MedicalServicesByCategoryIdDocument,
    options
  );
}
export function useMedicalServicesByCategoryIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MedicalServicesByCategoryIdQuery, MedicalServicesByCategoryIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MedicalServicesByCategoryIdQuery, MedicalServicesByCategoryIdQueryVariables>(
    MedicalServicesByCategoryIdDocument,
    options
  );
}
export type MedicalServicesByCategoryIdQueryHookResult = ReturnType<typeof useMedicalServicesByCategoryIdQuery>;
export type MedicalServicesByCategoryIdLazyQueryHookResult = ReturnType<typeof useMedicalServicesByCategoryIdLazyQuery>;
export type MedicalServicesByCategoryIdQueryResult = Apollo.QueryResult<
  MedicalServicesByCategoryIdQuery,
  MedicalServicesByCategoryIdQueryVariables
>;
export const MedicalServicesByIdsDocument = gql`
  query medicalServicesByIds($input: [String!]!) {
    medicalServicesByIds(input: $input) {
      data {
        id
        displayName
        url
        thumbnailUrl
        clinicEventId
        hospitalId
        price
        marketPrice
        textPrice
        originalPrice
        medicalCategoryId
        actionType
        medicalServiceSummary {
          ...summaryField
        }
      }
    }
  }
  ${SummaryFieldFragmentDoc}
`;

/**
 * __useMedicalServicesByIdsQuery__
 *
 * To run a query within a React component, call `useMedicalServicesByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicalServicesByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicalServicesByIdsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMedicalServicesByIdsQuery(
  baseOptions: Apollo.QueryHookOptions<MedicalServicesByIdsQuery, MedicalServicesByIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MedicalServicesByIdsQuery, MedicalServicesByIdsQueryVariables>(
    MedicalServicesByIdsDocument,
    options
  );
}
export function useMedicalServicesByIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MedicalServicesByIdsQuery, MedicalServicesByIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MedicalServicesByIdsQuery, MedicalServicesByIdsQueryVariables>(
    MedicalServicesByIdsDocument,
    options
  );
}
export type MedicalServicesByIdsQueryHookResult = ReturnType<typeof useMedicalServicesByIdsQuery>;
export type MedicalServicesByIdsLazyQueryHookResult = ReturnType<typeof useMedicalServicesByIdsLazyQuery>;
export type MedicalServicesByIdsQueryResult = Apollo.QueryResult<
  MedicalServicesByIdsQuery,
  MedicalServicesByIdsQueryVariables
>;
export const MedicalServicesRankingByCurationIdDocument = gql`
  query medicalServicesRankingByCurationId($id: ID!) {
    medicalServicesRankingByCurationId(id: $id) {
      id
      displayName
      url
      marketPrice
      price
      originalPrice
      thumbnailUrl
      clinicEventId
      textPrice
      medicalCategoryId
      actionType
      medicalCategory {
        id
        name
      }
      hospitalId
      medicalServiceSummary {
        id
        ratingMean
        reviewsCount
        latitude
        longitude
        openedDate
        medicalCategoryNames
        hospitalName
        clinicEventName
        address
        clinicScore
        mainDoctorEducation
      }
    }
  }
`;

/**
 * __useMedicalServicesRankingByCurationIdQuery__
 *
 * To run a query within a React component, call `useMedicalServicesRankingByCurationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicalServicesRankingByCurationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicalServicesRankingByCurationIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMedicalServicesRankingByCurationIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    MedicalServicesRankingByCurationIdQuery,
    MedicalServicesRankingByCurationIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MedicalServicesRankingByCurationIdQuery, MedicalServicesRankingByCurationIdQueryVariables>(
    MedicalServicesRankingByCurationIdDocument,
    options
  );
}
export function useMedicalServicesRankingByCurationIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MedicalServicesRankingByCurationIdQuery,
    MedicalServicesRankingByCurationIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MedicalServicesRankingByCurationIdQuery, MedicalServicesRankingByCurationIdQueryVariables>(
    MedicalServicesRankingByCurationIdDocument,
    options
  );
}
export type MedicalServicesRankingByCurationIdQueryHookResult = ReturnType<
  typeof useMedicalServicesRankingByCurationIdQuery
>;
export type MedicalServicesRankingByCurationIdLazyQueryHookResult = ReturnType<
  typeof useMedicalServicesRankingByCurationIdLazyQuery
>;
export type MedicalServicesRankingByCurationIdQueryResult = Apollo.QueryResult<
  MedicalServicesRankingByCurationIdQuery,
  MedicalServicesRankingByCurationIdQueryVariables
>;
export const NewMedicalServicesDocument = gql`
  query newMedicalServices {
    newMedicalServices {
      id
      displayName
      url
      thumbnailUrl
      clinicEventId
      hospitalId
      price
      marketPrice
      textPrice
      originalPrice
      medicalCategoryId
      actionType
      medicalServiceSummary {
        clinicEventName
        hospitalName
        ratingMean
        reviewsCount
        address
        latitude
        longitude
        openedDate
        mainDoctorEducation
        medicalCategoryNames
        priceDetails
      }
    }
  }
`;

/**
 * __useNewMedicalServicesQuery__
 *
 * To run a query within a React component, call `useNewMedicalServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewMedicalServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewMedicalServicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useNewMedicalServicesQuery(
  baseOptions?: Apollo.QueryHookOptions<NewMedicalServicesQuery, NewMedicalServicesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NewMedicalServicesQuery, NewMedicalServicesQueryVariables>(
    NewMedicalServicesDocument,
    options
  );
}
export function useNewMedicalServicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NewMedicalServicesQuery, NewMedicalServicesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NewMedicalServicesQuery, NewMedicalServicesQueryVariables>(
    NewMedicalServicesDocument,
    options
  );
}
export type NewMedicalServicesQueryHookResult = ReturnType<typeof useNewMedicalServicesQuery>;
export type NewMedicalServicesLazyQueryHookResult = ReturnType<typeof useNewMedicalServicesLazyQuery>;
export type NewMedicalServicesQueryResult = Apollo.QueryResult<
  NewMedicalServicesQuery,
  NewMedicalServicesQueryVariables
>;
export const NewMedicalServicesWithPaginationDocument = gql`
  query newMedicalServicesWithPagination($pagination: Pagination!) {
    newMedicalServicesWithPagination(pagination: $pagination) {
      data {
        id
        displayName
        actionType
        url
        thumbnailUrl
        clinicEventId
        hospitalId
        price
        marketPrice
        textPrice
        originalPrice
        medicalCategoryId
        medicalServiceSummary {
          clinicEventName
          hospitalName
          ratingMean
          reviewsCount
          address
          latitude
          longitude
          openedDate
          mainDoctorEducation
          medicalCategoryNames
          priceDetails
        }
      }
      totalCount
    }
  }
`;

/**
 * __useNewMedicalServicesWithPaginationQuery__
 *
 * To run a query within a React component, call `useNewMedicalServicesWithPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewMedicalServicesWithPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewMedicalServicesWithPaginationQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useNewMedicalServicesWithPaginationQuery(
  baseOptions: Apollo.QueryHookOptions<
    NewMedicalServicesWithPaginationQuery,
    NewMedicalServicesWithPaginationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NewMedicalServicesWithPaginationQuery, NewMedicalServicesWithPaginationQueryVariables>(
    NewMedicalServicesWithPaginationDocument,
    options
  );
}
export function useNewMedicalServicesWithPaginationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NewMedicalServicesWithPaginationQuery,
    NewMedicalServicesWithPaginationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NewMedicalServicesWithPaginationQuery, NewMedicalServicesWithPaginationQueryVariables>(
    NewMedicalServicesWithPaginationDocument,
    options
  );
}
export type NewMedicalServicesWithPaginationQueryHookResult = ReturnType<
  typeof useNewMedicalServicesWithPaginationQuery
>;
export type NewMedicalServicesWithPaginationLazyQueryHookResult = ReturnType<
  typeof useNewMedicalServicesWithPaginationLazyQuery
>;
export type NewMedicalServicesWithPaginationQueryResult = Apollo.QueryResult<
  NewMedicalServicesWithPaginationQuery,
  NewMedicalServicesWithPaginationQueryVariables
>;
export const MedicalServiceByIdDocument = gql`
  query medicalServiceById($id: ID!, $hospitalId: ID!) {
    medicalServiceById(id: $id) {
      id
      displayName
      url
      thumbnailUrl
      clinicEventId
      hospitalId
      price
      marketPrice
      textPrice
      originalPrice
      medicalCategoryId
      medicalServiceSummary {
        ...summaryField
      }
      hospital {
        id
        name
        address
        hospitalOperationTimes
      }
    }
    hospitalOperationTimesByHospitalId(hospitalId: $hospitalId) {
      id
      operationType
      from
      to
      operationOff
      hospitalId
      targetDate
      holidayName
    }
  }
  ${SummaryFieldFragmentDoc}
`;

/**
 * __useMedicalServiceByIdQuery__
 *
 * To run a query within a React component, call `useMedicalServiceByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicalServiceByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicalServiceByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      hospitalId: // value for 'hospitalId'
 *   },
 * });
 */
export function useMedicalServiceByIdQuery(
  baseOptions: Apollo.QueryHookOptions<MedicalServiceByIdQuery, MedicalServiceByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MedicalServiceByIdQuery, MedicalServiceByIdQueryVariables>(
    MedicalServiceByIdDocument,
    options
  );
}
export function useMedicalServiceByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MedicalServiceByIdQuery, MedicalServiceByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MedicalServiceByIdQuery, MedicalServiceByIdQueryVariables>(
    MedicalServiceByIdDocument,
    options
  );
}
export type MedicalServiceByIdQueryHookResult = ReturnType<typeof useMedicalServiceByIdQuery>;
export type MedicalServiceByIdLazyQueryHookResult = ReturnType<typeof useMedicalServiceByIdLazyQuery>;
export type MedicalServiceByIdQueryResult = Apollo.QueryResult<
  MedicalServiceByIdQuery,
  MedicalServiceByIdQueryVariables
>;
export const PopularMedicalServicesDocument = gql`
  query popularMedicalServices {
    popularMedicalServices {
      id
      name
      path
      rank
    }
  }
`;

/**
 * __usePopularMedicalServicesQuery__
 *
 * To run a query within a React component, call `usePopularMedicalServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopularMedicalServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopularMedicalServicesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePopularMedicalServicesQuery(
  baseOptions?: Apollo.QueryHookOptions<PopularMedicalServicesQuery, PopularMedicalServicesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PopularMedicalServicesQuery, PopularMedicalServicesQueryVariables>(
    PopularMedicalServicesDocument,
    options
  );
}
export function usePopularMedicalServicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PopularMedicalServicesQuery, PopularMedicalServicesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PopularMedicalServicesQuery, PopularMedicalServicesQueryVariables>(
    PopularMedicalServicesDocument,
    options
  );
}
export type PopularMedicalServicesQueryHookResult = ReturnType<typeof usePopularMedicalServicesQuery>;
export type PopularMedicalServicesLazyQueryHookResult = ReturnType<typeof usePopularMedicalServicesLazyQuery>;
export type PopularMedicalServicesQueryResult = Apollo.QueryResult<
  PopularMedicalServicesQuery,
  PopularMedicalServicesQueryVariables
>;
export const CreateReservationDocument = gql`
  mutation createReservation($data: ReservationHistoryInput!) {
    createReservation(data: $data) {
      id
      medicalServiceId
      hospitalId
      userId
      reservationAt
      name
      phone
      birth
      sex
      status
    }
  }
`;
export type CreateReservationMutationFn = Apollo.MutationFunction<
  CreateReservationMutation,
  CreateReservationMutationVariables
>;

/**
 * __useCreateReservationMutation__
 *
 * To run a mutation, you first call `useCreateReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReservationMutation, { data, loading, error }] = useCreateReservationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateReservationMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateReservationMutation, CreateReservationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateReservationMutation, CreateReservationMutationVariables>(
    CreateReservationDocument,
    options
  );
}
export type CreateReservationMutationHookResult = ReturnType<typeof useCreateReservationMutation>;
export type CreateReservationMutationResult = Apollo.MutationResult<CreateReservationMutation>;
export type CreateReservationMutationOptions = Apollo.BaseMutationOptions<
  CreateReservationMutation,
  CreateReservationMutationVariables
>;
export const MainBestReviewsDocument = gql`
  query mainBestReviews {
    mainBestReviews {
      ...review
    }
  }
  ${ReviewFragmentDoc}
`;

/**
 * __useMainBestReviewsQuery__
 *
 * To run a query within a React component, call `useMainBestReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMainBestReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMainBestReviewsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMainBestReviewsQuery(
  baseOptions?: Apollo.QueryHookOptions<MainBestReviewsQuery, MainBestReviewsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MainBestReviewsQuery, MainBestReviewsQueryVariables>(MainBestReviewsDocument, options);
}
export function useMainBestReviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MainBestReviewsQuery, MainBestReviewsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MainBestReviewsQuery, MainBestReviewsQueryVariables>(MainBestReviewsDocument, options);
}
export type MainBestReviewsQueryHookResult = ReturnType<typeof useMainBestReviewsQuery>;
export type MainBestReviewsLazyQueryHookResult = ReturnType<typeof useMainBestReviewsLazyQuery>;
export type MainBestReviewsQueryResult = Apollo.QueryResult<MainBestReviewsQuery, MainBestReviewsQueryVariables>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {},
};
export default result;
