export type UserAgent = 'IOS' | 'ANDROID' | 'BROWSER';

export default function getUserAgent() {
  if (typeof window !== 'undefined') {
    const agent = window?.navigator?.userAgent;
    if (agent.toLowerCase()?.includes('goodoc')) {
      if (agent.includes('iPhone') || agent.includes('iPod') || agent.includes('iPad')) {
        return 'IOS';
      }
      if (agent.includes('wv')) {
        return 'ANDROID';
      }
    }
    return 'BROWSER';
  }
}

export const IS_MOBILE: boolean = getUserAgent() !== 'BROWSER';
export const IS_BROWSER = !IS_MOBILE;

export function isDevelopment() {
  return ['proto', 'dev'].includes(process.env.NEXT_PUBLIC_ENV);
}
