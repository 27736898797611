import { gql } from '@apollo/client';

export const QUERY_ME = gql`
  query me {
    me {
      id
      name
      telephone: phone
      birth
      gender
      email
      yymmddi
    }
  }
`;

export const SignInDeviceInput = gql`
  input SignInDeviceInput {
    appVersion: String!
    deviceId: String!
    os: DeviceOsEnum!
    uuid: String!
  }
`;

export const SIGN_IN_DEVICE_DOCUMENT = gql`
  mutation signInDevice($input: SignInDeviceInput!) {
    signIn: signInDevice(data: $input) {
      accessToken: token
    }
  }
`;

export const SOCIAL_SIGN_IN_INPUT = gql`
  input SocialSignInInput {
    authorizationCode: String
    marketingConsentAgreement: Boolean

    """
    kakao, apple, naver, facebook
    """
    provider: SocialAuthProviderEnum!
    token: String!
    uid: String!
  }
`;

export const SIGN_IN_SOCIAL_DOCUMENT = gql`
  mutation signInSocial($input: SocialSignInInput!) {
    signIn: socialSignIn(data: $input) {
      accessToken: token
    }
  }
`;
