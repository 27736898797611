export type UserProfile = {
  uid: string | undefined;
  name?: string;
  phoneNumber?: string;
  frontRegistrationNumber?: string;
};
const PHONE_NUMBER_REGEX = /^010-?(\d{3,4})-?(\d{4})$/;

const useKakaoAuth = () => {
  const signInKakao = async () => {
    const token = await loginKakao();
    const profile = await getKakaoProfile();
    const refinedProfile = refineKakaoProfile(profile);

    return { token, profile: refinedProfile };
  };

  const signOutKakao = (callback: () => void) => {
    Kakao.Auth.logout(callback);
  };
  const refineKakaoProfile = (profile: Kakao.API.ApiResponse) => {
    const { id: uid, kakao_account: account } = profile;

    let phoneNumber = account.phone_number;
    if (phoneNumber && !PHONE_NUMBER_REGEX.test(phoneNumber)) {
      // 82+ 10-xxxx-xxxx 으로 들어오는 케이스
      phoneNumber = `0${account.phone_number.split(' ')[1]}`;
      phoneNumber = phoneNumber.replace(/-/gi, '');
    }

    return {
      uid: String(uid),
      name: account.name,
      phoneNumber,
    };
  };

  const loginKakao = (): Promise<string> =>
    new Promise((resolve, reject) => {
      Kakao.Auth.loginForm({
        success: ({ access_token: accessToken }) => {
          window.Kakao.Auth.setAccessToken(accessToken);
          resolve(accessToken);
        },
        fail: (error) => {
          reject(error);
        },
      });
    });

  const getKakaoProfile = (): Promise<Kakao.API.ApiResponse> =>
    new Promise((resolve, reject) => {
      Kakao.API.request({
        url: '/v2/user/me',
        success: (response) => {
          resolve(response);
        },
        fail: (error) => {
          reject(error);
        },
      });
    });

  return { signInKakao, signOutKakao };
};

export default useKakaoAuth;
