// URL QUERY STRINGS
export const URL_QUERY_STRING_NAME_LEVEL_1 = 'lv1';
export const URL_QUERY_STRING_NAME_LEVEL_2 = 'lv2';
export const URL_QUERY_STRING_NAME_LEVEL_3 = 'lv3';
export const URL_QUERY_STRING_NAME_FILTER = 'f';
export const URL_QUERY_STRING_NAME_LATITUDE = 'lt';
export const URL_QUERY_STRING_NAME_LONGITUDE = 'lg';
export const URL_QUERY_STRING_NAME_PARTNER_ONLY = 'po';
export const URL_QUERY_STRING_NAME_TARGET_CATEGORY = 'tc';

// LOCAL STORAGE KEYS
export const LOCAL_STORAGE_KEY_NAME_LAST_VIEWED_POPUP_ID = 'last-viewed-popup-id';
export const LOCAL_STORAGE_KEY_USER_SETTING_COORDINATE = 'userSettingCoordinate';
export const LOCAL_STORAGE_KEY_LAST_VISITED_EVENT = 'lastVisitedEvent';
export const LOCAL_STORAGE_KEY_CLINIC_EVENT_CART = 'clinic-event-cart';
export const LOCAL_STORAGE_KEY_EXHIBITION_NICKNAME = 'exhibition-nickname';

// SESSION STORAGE KEYS
export const SESSION_STORAGE_KEY_CLINIC_EVENT_REQUESTED = 'clinic-event-requested';
export const SESSION_STORAGE_KEY_BEST_REVIEW_ORIGINAL_ID = 'best-review-original-id';
export const SESSION_STORAGE_KEY_BEST_REVIEW_SHUFFLED_ID = 'best-review-shuffled-id';

// COOKIE NAMES
export const COOKIE_NAME_TOKEN = 'token';
export const COOKIE_NAME_USER_ID = 'userId';

// URL IDENTIFIERS
export const BULK_EVENTS_URL_PATH_IDENTIFIER = 'bulk';

// Shared Values
export const EVENT_CART_BULK_MAX_COUNT = 10;

// MINIMUM APP VERSION
export const MINIMUM_VERSION_CHAT_BOT = '5.1.45';
export const MINIMUM_VERSION_GRAY_HOSPITAL = '5.1.45';

// TEMP CODES FOR HARDCODING (가정의달 기획전)
export const FAMILY_CURATION_ID = '49';
export const FAMILY_PARENTS_CURATION_ID = '50';
export const FAMILY_CHILDREN_CURATION_ID = '51';

// DATA for temporary Hard coding
export const textConsultingExcludedOldHospitalIds = [
  34226, // 아비쥬의원 천호점, 4743
  227946, // 아비쥬의원 구로점, 10939
  201983, // 아비쥬의원 청량리점, 14052
  176514, // 아비쥬의원 신천점, 17078
  227948, // 아비쥬의원 범계점, 17079
  176515, // 아비쥬의원 부평점, 23330
  201988, // 아비쥬의원 청담점, 29511
  201981, // 아비쥬의원 강남점, 35631
  227947, // 아비쥬의원 인천구월점, 35632
  201982, // 아비쥬의원 수원점, 63377
  201986, // 아비쥬의원 노원점, 72713
  201987, // 아비쥬의원 명동점, 72714
  227945, // 아비쥬의원 일산점, 79477
  236008, // 아비쥬의원 대전점, 79534
  236558, // 아비쥬의원 분당점, 79580
  235920, // 벨뷰의원, 16486
];

export const textConsultingExcludedHospitalIds = [
  4743, // 아비쥬의원 천호점, 34226
  10939, // 아비쥬의원 구로점, 227946
  14052, // 아비쥬의원 청량리점, 201983
  17078, // 아비쥬의원 신천점, 176514
  17079, // 아비쥬의원 범계점, 227948
  23330, // 아비쥬의원 부평점, 176515
  29511, // 아비쥬의원 청담점, 201988
  35631, // 아비쥬의원 강남점, 201981
  35632, // 아비쥬의원 인천구월점, 227947
  63377, // 아비쥬의원 수원점, 201982
  72713, // 아비쥬의원 노원점, 201986
  72714, // 아비쥬의원 명동점, 201987
  79477, // 아비쥬의원 일산점, 227945
  79534, // 아비쥬의원 대전점, 236008
  79580, // 아비쥬의원 분당점, 236558
  16486, // 벨뷰의원, 235920
];
