import { UserContext } from 'contexts/UserContext';
import { useContext, useEffect, useState, useMemo } from 'react';
import { compareVersion } from 'utils';
import getUserAgent, { UserAgent } from 'utils/userAgent';

const useUserAgent = () => {
  const [userAgent, setUserAgent] = useState<UserAgent>();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const isBrowser = useMemo<boolean>(() => !isMobile, [isMobile]);
  const { device } = useContext(UserContext);

  useEffect(() => {
    const userAgent = getUserAgent();
    setUserAgent(userAgent);
    setIsMobile(userAgent === 'IOS' || userAgent === 'ANDROID');
  }, []);

  // eslint-disable-next-line no-confusing-arrow
  const checkMiniumAppVersion = (version: string) => isMobile && (compareVersion(version, device.appVersion) || false);

  return { userAgent, isMobile, isBrowser, checkMiniumAppVersion };
};

export default useUserAgent;
