export function sleep(time: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}

// return true if versionB is same or greater than versionA (true if versionA <= versionB)
// if invalid version comparison, return null
export function compareVersion(versionA: string, versionB: string): boolean | null {
  if (!versionA || !versionB) return null;

  let compareResult = true;
  const splitVersionA = versionA.split('.');
  const splitVersionB = versionB.split('.');

  const length = Math.max(splitVersionA.length, splitVersionB.length);

  for (let i = 0; i < length; i += 1) {
    const a = splitVersionA[i] ? parseInt(splitVersionA[i], 10) : 0;
    const b = splitVersionB[i] ? parseInt(splitVersionB[i], 10) : 0;
    if (a !== b) {
      compareResult = a < b;
      break;
    }
  }
  return compareResult;
}

export function composeFunnelledQueryString(funnel: string, routerPath: string) {
  const queryString = routerPath.split('?')[1];
  return queryString
    ? queryString.includes('funnel')
      ? queryString
      : `${queryString}&funnel=${funnel}`
    : `funnel=${funnel}`;
}

export function shuffleArray<T>(array: T[]): T[] {
  const newArray = [...array];
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
}
